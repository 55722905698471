import React from "react";

import { useLocation } from "react-router-dom";
import LinkCreatorServices from "../services/LinkCreatorServices.js";

const FEATURES = {
  free: [
    {id: "drawWireframes", text: "Draw out your wireframes"},
    {id: "connect", text: "Connect screens together interactively"},
    {id: "screen", text: "Design for watch, phone or tablet sized screens"},
    {id: "autoDark", text: "Automatic conversion to light/dark mode"}
  ],
  pro: [
    {id: "everythingFromFree", text: "Everything in the basic version"},
    {id: "textFields", text: "Text and data fields"},
    {id: "toggles", text: "Custom toggles (e.g. check boxes)"},
    {id: "templates", text: "Use templates (e.g. for tab bars and repeatable sections)"},
    {id: "camera", text: "Add camera fields"},
    {id: "share", text: "Share your creations on the web"},
    {id: "screenRecording", text: "Record screen walkthroughs"},
    {id: "logic", text: "Use the advanced logic editor for more complext flows"},
    {id: "fonts", text: "Create your own custom fonts"}
  ]
}

export default({theme}) => {

    const location = useLocation();

    const localStyles = {
      titleHolder: {
        height: 100
      },
      priceInfo: {
        fontWeight: "700",
        marginTop: 10,
        color: theme.colors.lightText
      },
      pro: {
        backgroundColor: theme.colors.primary,
        fontWeight: "700",
        fontSize: 16,
        borderRadius: 30,
        width: 50,
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.colors.alwaysWhite
      },
      list: {
        listStyle: "inside",
        paddingLeft: 0,
        color: theme.colors.primary
      },
      basic: {
        backgroundColor: "transparent",
        fontWeight: "700",
        fontSize: 18,
        width: 50,
        display: "flex",
        alignItems: "center",
        color: theme.colors.black
      },
      li: {
        paddingBottom: 6,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        flex: 1
      },
      bullet: {
        backgroundColor: theme.colors.primary,
        width: 10,
        height: 10,
        borderRadius: 5,
        marginRight: 10,
        marginTop: 5
      },
      feature: {
        fontSize: 16,
        color: theme.colors.black,
        flex: 1
      },
      featureList: {
        display: "flex",
        flexDirection: "column"
      }
    }; 

    const renderIntroBox = () => {
      if (LinkCreatorServices.isInApp(location)) {
        return null;
      }

      return (
        <div style={theme.styles.primaryBox}>
            <h1>Roughups Pricing</h1>
            <p style={theme.styles.bigP}>You can get started with Roughups for free, but to get the most out of it you'll want to upgrade to our PRO version. You can do this in app via a subscription.</p>
        </div>
      );
    };
    
    return (
      <div style={theme.styles.contentContainer}>
        {renderIntroBox()}  
          <div className="grid-2x">
            <div style={theme.styles.lightBoxCell}>
              <div style={localStyles.featureList}>
                <div style={localStyles.titleHolder}>
                  <div style={localStyles.basic}>Basic</div>
                  <div style={localStyles.priceInfo}>FREE</div>
                </div>
                
                {FEATURES.free.map((feature) => {
                  return (
                    <div key={feature.id} style={localStyles.li}>
                      <div style={localStyles.bullet}></div>
                      <div style={localStyles.feature}>{feature.text}</div>
                    </div>
                  );
                })}
              </div>
              
            </div>
            <div style={theme.styles.lightBoxCell}>
              <div style={localStyles.featureList}>
                <div style={localStyles.titleHolder}>
                  <div style={localStyles.pro}>PRO</div>
                  <div style={localStyles.priceInfo}>£3.99 a month</div>
                </div>
                
                {FEATURES.pro.map((feature) => {
                  return (
                    <div key={feature.id} style={localStyles.li}>
                      <div style={localStyles.bullet}></div>
                      <div style={localStyles.feature}>{feature.text}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div style={theme.styles.footer}>
              <div style={theme.styles.footerText}>Need more help or have questions? Emails us <a href="mailto:hello@roughups.com">hello@roughups.com</a> or ask on Twitter <a href="https://twitter.com/@roughups">@roughups</a>. We also love to hear your ideas for future features.</div>
          </div>
        </div>
    ); 
};