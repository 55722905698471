import React from "react";
import ZipServices from "../services/ZipServices";

const ANIMATION_CLASS_NAMES = {
    animation_rotate: "simpleAnimateRotate",
    animation_scale: "simpleAnimateScale"
};

export default({hotspot, component, scaledCoordinates, disableHotspotInteractions, theme}) => {
    const localStyles = {
        image: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height,
            objectFit: "cover"
        }
    };

    const getClassName = () => {
        if (disableHotspotInteractions) {
            return null;
        }
        
        if (ANIMATION_CLASS_NAMES[component.animationType]) {
            return ANIMATION_CLASS_NAMES[component.animationType];
        }

        return null;
    };

    return (
        <img 
            alt="Hotspot"
            className={getClassName()}
            src={ZipServices.getCachedImage(Object.keys(component.screens)[0], theme.mode)}
            style={localStyles.image}
            />
    );
}