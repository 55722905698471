import React, { useState } from "react";

export default({id, theme, onPasswordEntered}) => {

    const [password, setPassword] = useState("");

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        input: {
            paddingBottom: 5,
            borderStyle: "none",
            borderBottomStyle: "solid",
            borderBottomWidth: 2,
            borderBottomColor: theme.colors.black,
            fontWeight: "700",
            fontSize: 16,
            placeholderColor: theme.colors.light,
            outline: "none",
            textAlign: "center",
            backgroundColor: "transparent",
            color: theme.colors.black,
            borderRadius: 0,
            width: 150
        },
        invalid: {
            fontSize: 14,
            fontWeight: "700",
            marginBottom: 16,
            color: theme.colors.black
        }
    };

    const onKeyDown = (evt) => {
        if (evt.keyCode === 13) {
            evt.preventDefault();
            evt.stopPropagation();
            onPasswordEntered(password);
        }
    }

    const renderError = () => {
        if (!window.sessionStorage[id]) {
            return null;
        }

        return (
            <div style={localStyles.invalid}>Invalid Password</div>
        );
    }

    return (
        <div style={localStyles.container}>
            {renderError()}
            <input 
                type="password"
                placeholder="Enter Password"
                style={localStyles.input}
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                onKeyDown={(evt) => onKeyDown(evt)}
                />
        </div>
    );
};