import React from "react";
import ZipServices from "../services/ZipServices";

const FONT_HEIGHT_OFFSET = 0.5;
const CUSTOM_FONT_HEIGHT_OFFSET = 0.3;
const PADDING_RATIO = 0.03;
const MAX_PADDING = 10;

export default({hotspot, disableHotspotInteractions, theme, scale, fields, fieldId, onChange}) => {
    if (!(hotspot.action && hotspot.action.type === "field")) {
        return null;
    }

    if (hotspot.fieldType && hotspot.fieldType === "toggle") {
        return null;
    }

    const getWidth = () => {
        if (hotspot.coordinates.x2 > hotspot.coordinates.x) {
            return (hotspot.coordinates.x2 - hotspot.coordinates.x) * scale;
        } else {
            return (hotspot.coordinates.x - hotspot.coordinates.x2) * scale;
        }
    }

    const getPadding = () => {
        const calculatedPadding = getWidth() * PADDING_RATIO;
    
        if (calculatedPadding > MAX_PADDING) {
            return MAX_PADDING;
        }

        return calculatedPadding;
    }

    const getFontHeight = () => {
        var heightOfBox;

        if (hotspot.coordinates.y2 > hotspot.coordinates.y) {
            heightOfBox = (hotspot.coordinates.y2 - hotspot.coordinates.y) * scale;
        } else {
            heightOfBox = (hotspot.coordinates.y - hotspot.coordinates.y2) * scale;
        }

        if (ZipServices.font) {
            return heightOfBox - (heightOfBox * CUSTOM_FONT_HEIGHT_OFFSET);
        }

        return heightOfBox - (heightOfBox * FONT_HEIGHT_OFFSET);
	}

    const localStyles = {
        input: {
            backgroundColor: "transparent",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            outline: "none",
            borderStyle: "none",
            paddingLeft: getPadding(),
            paddingRight: getPadding(),
            textAlign: hotspot.textAlignment || "left",
            fontFamily: ZipServices.font ? `"roughups_font", "${theme.fonts.scribble}"` : theme.fonts.scribble,
            fontSize: getFontHeight(),
            color: theme.colors.black
        }
    };

    const getValue = () => {
        if (fields[fieldId]) {
            return fields[fieldId];
        }

        return "";
    }

    return (
        <input 
            type="text" 
            className="custom-font-placeholder"
            value={getValue()}
            placeholder={hotspot.placeholder}
            autoCapitalize="none"
            autoCorrect="none"
            readOnly={hotspot.readOnly || disableHotspotInteractions}
            onChange={(evt) => onChange(evt.target.value.toLowerCase())}
            style={localStyles.input}/>
    );
};