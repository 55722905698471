import React from "react";

import ScreenServices from "../services/ScreenServices";

const MAX_RADIUS = 16;

export default({theme, version, scale, children}) => {

    const screenSize = ScreenServices.getScreenSize(version, scale);

    const getBorderRadius = () => {
        var radius = screenSize.width * ScreenServices.SCREEN_BORDER_RADIUS_RATIO;
        
        if (radius > MAX_RADIUS) {
            radius = MAX_RADIUS;
        }
    
        return radius;
    };

    const localStyles = {
        phoneFrame: {
            display: "flex",
            width: screenSize.width,
            height: screenSize.height,
            borderRadius: getBorderRadius(),
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            backgroundColor: theme.colors.white,
            userSelect: "none",
            boxShadow: theme.colors.playerShadow,
            transform: "translate3d(0,0,0)"
        },
    }

    return (
        <div className="print-bordered" style={localStyles.phoneFrame}>
            {children}
        </div>
    );
};