import React from "react";
import ZipServices from "../services/ZipServices";
import Hotspots from "./Hotspots";
import {contain} from "intrinsic-scale";

export default({hotspot, version, component, scale, enabled, disableHotspotInteractions, fields, onChangeField, onChangeScreen, scaledCoordinates, showLocation, onChangeShowHotspotLocations, theme}) => {
    const localStyles = {
        container: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height
        },
        image: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height,
            objectFit: "contain"
        }
    };

    const screen = component.screens[Object.keys(component.screens)[0]];

    const getResizedTemplateCoordinates = (firstScreen) => {
		const hotspotWidth = hotspot.coordinates.x2 - hotspot.coordinates.x;
		const hotspotHeight = hotspot.coordinates.y2 - hotspot.coordinates.y;
		var { width, height, x, y } = contain(hotspotWidth, hotspotHeight, component.size.width, component.size.height);

		var widthRatio = width / component.size.width;
		var heightRatio = height / component.size.height;

        if (widthRatio === 1 && heightRatio === 1) {
            return firstScreen.hotspots;
        }

		var updatedHotspots = [];
		
        if (firstScreen && firstScreen.hotspots) {
            Object.keys(firstScreen.hotspots).forEach((hotspotId) => {
                const currentHotspot = Object.assign({}, firstScreen.hotspots[hotspotId]);
                updatedHotspots.push(currentHotspot);
                updatedHotspots[updatedHotspots.length - 1].coordinates = {
                    x: (currentHotspot.coordinates.x * widthRatio) + x,
                    y: (currentHotspot.coordinates.y * heightRatio) + y,
                    x2: (currentHotspot.coordinates.x2 * widthRatio) + x,
                    y2: (currentHotspot.coordinates.y2 * heightRatio) + y,
                };
            });
    
        }

		
		return updatedHotspots;
	}

    return (
        <div style={localStyles.container}>
            <img 
                alt="Template"
                src={ZipServices.getCachedImage(Object.keys(component.screens)[0], theme.mode)}
                style={localStyles.image}
                />
            <Hotspots
                theme={theme}
                isComponent={true}
                withinComponent={true}
                version={version}
                hotspots={getResizedTemplateCoordinates(screen)}
                scale={scale}
                enabled={enabled}
                disableHotspotInteractions={disableHotspotInteractions}
                fields={fields}
                showLocationOverride={showLocation}
                showHotspotLocations={showLocation}
                onChangeShowHotspotLocations={onChangeShowHotspotLocations}
                component={component}
                onChangeField={onChangeField}
                onChangeScreen={(changeToId) => onChangeScreen(changeToId)}
            />
            
        </div>
    );
}