import React from "react";
import { useParams, useLocation } from "react-router-dom";
import lessons from "../config/lessons.js"
// eslint-disable-next-line import/no-webpack-loader-syntax
import ReactMarkdown from "react-markdown";
import ScrollToTop from "../components/ScrollToTop";
import LessonVideo from "../components/LessonVideo.js";
import LinkCreatorServices from "../services/LinkCreatorServices.js";

export default({theme}) => {
    const { id } = useParams();
    const location = useLocation();

    if (!lessons[id]) {
      return null;
    }

    const localStyles = {
      markdown: {
        marginTop: 30
      }
    };

    const lesson = lessons[id];

    const renderMarkdown = () => {
      if (!lesson.content) {
        return null;
      }

      return (
        <ReactMarkdown
            children={lesson.content}
        />
      );
    };

    
    return (
          <div className={LinkCreatorServices.isInApp(location) ? "in-app-straight-to-content" : "straight-to-content"} style={LinkCreatorServices.isInApp(location) ? theme.styles.inAppContent : theme.styles.straightToContent}>
            <ScrollToTop/>
            <h1>{lesson.title}</h1>
            <p>{lesson.description}</p>
            <LessonVideo
              theme={theme}
              videoId={lesson.video_id}
            />
            <div style={localStyles.markdown}>
              {renderMarkdown()}
            </div>
            
          </div>
          

    ); 
};