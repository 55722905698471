import logo from "./images/roughups_logo.svg";
import logoDark from "./images/roughups_logo_dark.svg";
import allScreens from "./images/all_screens@2x.png";
import allScreenDark from "./images/all_screens_dark@2x.png";
import play from "./images/play@2x.png";
import playDark from "./images/play_dark@2x.png";

// Screenshots
import ideas from "./images/screenshots/ideas1@2x.png";
import ideasDark from "./images/screenshots/ideas_dark@2x.png";
import camera from "./images/screenshots/camera@2x.png";
import cameraDark from "./images/screenshots/camera_dark@2x.png";
import connect from "./images/screenshots/connect@2x.png";
import connectDark from "./images/screenshots/connect_dark@2x.png";
import fonts from "./images/screenshots/fonts@2x.png";
import fontsDark from "./images/screenshots/fonts_dark@2x.png";
import textField from "./images/screenshots/textfield@2x.png";
import textFieldDark from "./images/screenshots/textfield_dark@2x.png";
import share from "./images/screenshots/share@2x.png";
import shareDark from "./images/screenshots/share_dark@2x.png";
import toggle from "./images/screenshots/toggle@2x.png";
import toggleDark from "./images/screenshots/toggle_dark@2x.png";
import templates from "./images/screenshots/templates@2x.png";
import templatesDark from "./images/screenshots/templates_dark@2x.png";
import logic from "./images/screenshots/logic@2x.png";
import logicDark from "./images/screenshots/logic_dark@2x.png";
import screenSizes from "./images/screenshots/screensizes@2x.png";
import screenSizesDark from "./images/screenshots/screensizes_dark@2x.png";
import screenRecording from "./images/screenshots/screenrecording@2x.png";
import screenRecordingDark from "./images/screenshots/screenrecording_dark@2x.png";



const FONTS = {
    scribble: "Indie Flower",
    default: "Nunito"
};

const COLORS = {
    light: {
        black: "#000000",
        white: "#ffffff",
        frameBorder: "#000000",
        logoCircle: "#0091FF",
        logoBorder: "#000000",
        logoSquiggle: "#000000",
        primary: "#0091FF",
        transparentPrimary: "rgba(0,145,255,0.2)",
        light: "#F5F5F5",
        lightText: "#757271",
        videoBackground: "rgba(244, 243, 249, 1.00)",
        background: "rgba(244, 243, 249, 1.00)",
        textOnPrimary: "#ffffff",
        overlay: "rgba(0,0,0,0.2)",
        alwaysBlack: "#000000",
        alwaysWhite: "#ffffff",
        playerBarShadow: "0 2px 27px 13px rgba(220,216,216,0.50)",
        frameShadow: "0 2px 27px 13px rgba(220,216,216,0.50)",
        topBarShadow: "0 2px 27px 13px rgba(113,113,113,0.50)"
    },
    dark: {
        black: "#ffffff",
        white: "#000000",
        frameBorder: "rgba(0,0,0,0.95)",
        alwaysBlack: "#000000",
        alwaysWhite: "#ffffff",
        logoCircle: "#0091FF",
        logoBorder: "#7a7a7a",
        logoSquiggle: "#000000",
        primary: "#0091FF",
        overlay: "rgba(0,0,0,0.2)",
        transparentPrimary: "rgba(0,145,255,0.2)",
        videoBackground: "rgba(244, 243, 249, 1.00)",
        light: "rgba(255,255,255,0.2)",
        lightText: "#ffffff",
        background: "#414141",
        textOnPrimary: "#ffffff",
        playerBarShadow: "0 2px 27px 13px #252525",
        frameShadow: "0 2px 27px 13px rgba(220,216,216,0.50)",
        topBarShadow: "0 2px 57px 13px rgba(59, 59, 61, 0.8)"
    }

};

const styles = (theme) => {
    const colors = COLORS[theme];

    const CONTENT_MAX_WIDTH = 700;

    return {
        contentContainer: {
            paddingBottom: 100,
            paddingTop: 60
        },
        inAppContentContainer: {
            paddingBottom: 100,
            paddingTop: 30
        },
        straightToContent: {
            paddingTop: 150,
            paddingBottom: 100,
            justifyContent: "flex-start"
        },
        inAppContent: {
            paddingTop: 30,
            paddingBottom: 100,
            justifyContent: "flex-start"
        },
        content: {
            maxWidth: CONTENT_MAX_WIDTH,
        },
        centeredContent: {
            maxWidth: CONTENT_MAX_WIDTH,
            
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        background: {
            backgroundColor: colors.background,
            color: colors.black,
            minHeight: "100vh"
        },
        bigP: {
            fontSize: 20,
            opacity: 0.9
        },
        tagline: {
            fontSize: 16,
            color: colors.lightText
        },
        centered: {
            textAlign: "center"
        },
        plainContent: {
            paddingTop: 30
        },
        primaryBox: {
            backgroundColor: colors.primary,
            padding: 30,
            paddingTop: 50,
            paddingBottom: 50,
            borderRadius: 12,
            color: colors.textOnPrimary
        },
        footer: {
            paddingTop: 30
        },
        footerText: {
            fontWeight: "700",
            opacity: 0.8
        },
        lightBox: {
            backgroundColor: colors.white,
            marginTop: 30,
            padding: 30,
            borderRadius: 12,
            color: colors.black
        },
        lightP: {
            color: colors.lightText
        },
        lightBoxCell: {
            display: "flex",
            padding: 30,
            flexDirection: "column",
            backgroundColor: colors.white,
            borderRadius: 12,
            color: colors.black
        }
    };
};

const createStyle = (styles) => {
    // Turns an array of styles into single style object
    var styleObj = {};

    if (!styles || !Array.isArray(styles) || styles.length === 0) {
        return {};
    }

    styles.forEach((style) => {
        Object.keys(style).forEach((key) => {
            styleObj[key] = style[key]
        });
    });

    return styleObj;
};

export default {
    light: {
        mode: "light",
        colors: COLORS.light,
        fonts: FONTS,
        images: {
            logo: logo,
            allScreens: allScreens,
            play: play,
            screenshots: {
                ideas: ideas,
                camera: camera,
                connect: connect,
                fonts: fonts,
                textField: textField,
                share: share,
                toggle: toggle,
                templates: templates,
                logic: logic,
                screenSizes: screenSizes,
                screenRecording: screenRecording
            }
        },
        styles: styles("light"),
        createStyle: createStyle
    },
    dark: {
        mode: "dark",
        colors: COLORS.dark,
        fonts: FONTS,
        images: {
            logo: logoDark,
            allScreens: allScreenDark,
            play: playDark,
            screenshots: {
                ideas: ideasDark,
                camera: cameraDark,
                connect: connectDark,
                fonts: fontsDark,
                textField: textFieldDark,
                share: shareDark,
                toggle: toggleDark,
                templates: templatesDark,
                logic: logicDark,
                screenSizes: screenSizesDark,
                screenRecording: screenRecordingDark
            }
        },
        styles: styles("dark"),
        createStyle: createStyle
    }
}