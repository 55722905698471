import React from "react";

export default({link, onClick}) => {
    const localStyles = {
        link: {
            fontWeight: "800",
            userSelect: "none",
            cursor: "pointer",
            opacity: link.on ? 1 : 0.5
        }
    };

    return (
        <div key={link.id} style={localStyles.link} onClick={onClick}>
            {link.label}
        </div>
    )
};