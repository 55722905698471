import React from "react";
import ZipServices from "../services/ZipServices";

const ANIMATION_CLASS_NAMES = {
    animation_rotate: "simpleAnimateRotate",
    animation_scale: "simpleAnimateScale"
};

export default({hotspot, scaledCoordinates, theme, disableHotspotInteractions}) => {
    if (!hotspot.componentType || hotspot.componentType !== "simple_animate") {
        return null;
    }

    const localStyles = {
        image: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height
        }
    };

    const getClassName = () => {
        if (disableHotspotInteractions) {
            return null;
        }

        if (ANIMATION_CLASS_NAMES[hotspot.animationType]) {
            return ANIMATION_CLASS_NAMES[hotspot.animationType];
        }

        return null;
    };

    return (
        <img 
            alt="Hotspot"
            className={getClassName()}
            src={ZipServices.getCachedImage(hotspot.id, theme.mode)}
            style={localStyles.image}
            />
    );
}