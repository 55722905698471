import React from "react";

import Logo from "./Logo";
import TopBarLink from "./TopBarLink";

import {
    useHistory,
    useLocation
} from "react-router-dom";

const LINKS = [
    {id: "about", label: "About", url: "/", default: true},
    {id: "learn", label: "Learn", url: "/learn"},
    {id: "pricing", label: "Pricing", url: "/pricing"}
];

const APP_STORE_URL = "https://apps.apple.com/au/app/roughups/id1475449385";

export default({theme}) => {

    const history = useHistory();
    const location = useLocation();

    if ((location.search && location.search.indexOf("app=true") >= 0) || window.location.href.indexOf("upgrade") >= 0) {
        return null;
    }

    const localStyles = {
        outerContainer: {
            position: "fixed",
            top: 30,
            backgroundColor: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999
        },
        links: {
            marginLeft: 30,
            marginRight: 30,
            width: 200,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
        },
        container: {
            height: 60,
            paddingLeft: 30,
            paddingRight: 30,
            color: theme.colors.black,
            backgroundColor: theme.colors.white,
            borderRadius: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: theme.colors.topBarShadow
        },
        logo: {
            width: 30,
            height: 30
        },
        getButton: {
            cursor: "pointer",
            userSelect: "none",
            backgroundColor: theme.colors.primary,
            padding: 5,
            borderRadius: 18,
            color: theme.colors.textOnPrimary,
            fontWeight: "800",
            paddingLeft: 15,
            paddingRight: 15
        },
        right: {
            display: "flex",
            justifyContent: "flex-end"
        }
    };

    const getLinksWithOnStatus = () => {
        var somethingOn = false;

        var linksWithOn = [];

        LINKS.forEach((link) => {
            linksWithOn.push(link);

            if (location.pathname.indexOf(link.url) >= 0 && !link.default) {
                somethingOn = true;
                linksWithOn[linksWithOn.length - 1].on = true;
            } else {
                linksWithOn[linksWithOn.length - 1].on = false;
            }
        });



        if (!somethingOn) {
            // Set the default to on
            for (var i = 0; i < linksWithOn.length; i++) {
                if (linksWithOn[i].default) {
                    linksWithOn[i].on = true;
                    break;
                }
            }
        }

        return linksWithOn;
    };

    const goToAppStore = () => {
        window.location.href = APP_STORE_URL;
    };

    return (
        <div  style={localStyles.outerContainer}>
            <div className="top-bar" style={localStyles.container}>
                <Logo
                    style={localStyles.logo}
                    theme={theme}
                />
                <div style={localStyles.links}>
                    {getLinksWithOnStatus().map((link) => {
                        return (
                            <TopBarLink
                                key={link.id}
                                link={link}
                                onClick={() => history.push(link.url)}
                            />
                        )
                    })}
                </div>
                <div style={localStyles.right}>
                    <div style={localStyles.getButton} onClick={() => goToAppStore()}>
                        Get
                    </div>                    
                </div>
            </div>
        </div>
        
    );
};