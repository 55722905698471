import ZipServices from "./ZipServices";

export default {
    async loadFont() {
        if (ZipServices.font) {
            const font = new FontFace('roughups_font', `url(data:font/ttf;base64,${ZipServices.font})`);
            await font.load();
            document.fonts.add(font);    
        }
	}
}