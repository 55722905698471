import React from "react";
import HotspotComponentSimpleAnimate from "./HotspotComponentSimpleAnimate";
import HotspotComponentTemplate from "./HotspotComponentTemplate";

export default({hotspot, version, enabled, disableHotspotInteractions, scaledCoordinates, scale, theme, showLocation, onChangeShowLocation, onChangeShowHotspotLocations, onChangeScreen}) => {
    if (!hotspot.componentId) {
        return null;
    }

    if (!version || !version.definition.components || !version.definition.components[hotspot.componentId]) {
        return null;
    }
    
    // Get the component
    const component = version.definition.components[hotspot.componentId];

    if (component.type.id === "simple_animate") {
        return (
            <HotspotComponentSimpleAnimate
                component={component}
                theme={theme}
                hotspot={hotspot}
                disableHotspotInteractions={disableHotspotInteractions}
                scaledCoordinates={scaledCoordinates}/>
        );
    } else if (component.type.id === "template") {
        return (
            <HotspotComponentTemplate
                component={component}
                version={version}
                scale={scale}
                enabled={true}
                theme={theme}
                showLocation={showLocation}
                onChangeShowLocation={onChangeShowLocation}
                onChangeShowHotspotLocations={onChangeShowHotspotLocations}
                showLocationOverride={showLocation}
                hotspot={hotspot}
                disableHotspotInteractions={disableHotspotInteractions}
                onChangeScreen={onChangeScreen}
                scaledCoordinates={scaledCoordinates}/>
        )
    }

    // Component Type not found
    return null;
};