import * as React from "react";

function AllIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 44 54"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd">
        <rect y={29.368} width={19.179} height={24.632} rx={4.75} />
        <rect x={24.821} y={29.368} width={19.179} height={24.632} rx={4.75} />
        <rect x={24.821} width={19.179} height={24.632} rx={4.75} />
        <rect width={19.179} height={24.632} rx={4.75} />
      </g>
    </svg>
  );
}

export default AllIcon;

