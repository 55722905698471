import React, { useState, useEffect, useReducer } from "react";
import LoadingProgress from "./LoadingProgress";
import Player from "./Player";
import AllScreens from "./AllScreens";
import Header from "./Header";

const FIELD_UPDATE_REPLACE = "replace";
const FIELD_UPDATE_SET_FIELD = "set"

const PAGE_ALL = "#all";

export default ({id, loading, error, progress, requiresPassword, onPasswordEntered, unknownProgress, version, theme}) => {

    const [fields, updateFields] = useReducer((currentFields, action) => {
        if (action.type === FIELD_UPDATE_REPLACE) {
            return action.values;
        } else {
            var updatedFields = Object.assign({}, currentFields);
            updatedFields[action.fieldId] = action.value;
            return updatedFields;
        }
    }, {});

    const [hash, setHash] = useState(window.location.hash);
    const [initialScreen, setInitialScreen] = useState(null);

    const getFieldDefaults = () => {
		// In here we look through all hotspots to see if we have any default variable values
		// to set
		var defaults = [];

		if (version.definition.screens && Object.keys(version.definition.screens).length > 0) {
			Object.keys(version.definition.screens).forEach((screenId) => {
				if (version.definition.screens[screenId].hotspots && Object.keys(version.definition.screens[screenId].hotspots).length) {
					Object.keys(version.definition.screens[screenId].hotspots).forEach((hotspotId) => {
						// We have hotspots to look through
						const screenHotspot = version.definition.screens[screenId].hotspots[hotspotId];
						
						if (screenHotspot.defaultValue) {
							var fieldId = screenHotspot.fieldName || screenHotspot.id;
							defaults[fieldId] = screenHotspot.defaultValue;
						}
					});
				}
			});
		}

		return defaults;
	}

    useEffect(() => {
        window.onhashchange = () => {
            setHash(window.location.hash);
        };
    }, []);

    useEffect(() => {
        if (version) {
            updateFields({
                type: FIELD_UPDATE_REPLACE, 
                values: getFieldDefaults()
            });
        }
    }, [version]); // eslint-disable-line

    

    if (loading || error || requiresPassword) {
        return (
            <LoadingProgress
                id={id}
                error={error}
                loading={loading}
                requiresPassword={requiresPassword}
                progress={progress}
                unknown={unknownProgress}
                onPasswordEntered={onPasswordEntered}
                theme={theme}
            />
        );
    }

    const onChangeField = (field, value) => {
        updateFields({
            type: FIELD_UPDATE_SET_FIELD,
            fieldId: field, 
            value: value
        });
    };

    const onGoToPlayerScreen = (screen) => {
        setInitialScreen(screen);
        window.location.hash = "";
    }

    const renderPage = () => {
        if (!hash || hash === "") {
            return (
                <Player
                    version={version}
                    initialScreen={initialScreen}
                    theme={theme}
                    fields={fields}
                    onChangeField={((field, value) => onChangeField(field, value))}
                />
            );
        } else if (hash === PAGE_ALL) {
            return (
                <AllScreens
                    version={version}
                    theme={theme}
                    fields={fields}
                    onGoToScreen={(screen) => onGoToPlayerScreen(screen)}
                />
            );
        } else {
            return null;
        }
    };

    const onPrint = () => {
        window.print();
    };

    return (
        <div style={theme.styles.background}>
            <Header
                theme={theme}
                hash={hash}
                loading={loading}
                onPrint={() => onPrint()}
            />
            {renderPage()}
        </div>
        
    );
};