import React from "react";
import lessons from "../config/lessons.js"
import { useHistory, useLocation } from "react-router-dom";
import LinkCreatorServices from "../services/LinkCreatorServices.js";

export default({theme}) => {
    const history = useHistory();
    const location = useLocation();

    const onGoToTutorial = (lesson) => {
      history.push(LinkCreatorServices.makeLink(`/learn/${lesson.id}`, location));
    };

    const renderIntroBox = () => {
      if (LinkCreatorServices.isInApp(location)) {
        return null;
      }

      return (
        <div style={theme.styles.primaryBox}>
            <h1>Learn how to use Roughups</h1>
            <p style={theme.styles.bigP}>We’ve created some tutorials & videos to teach you about all of the powerful features of Roughups.</p>
        </div>
      );
    };
    
    return (
        <div style={LinkCreatorServices.isInApp(location) ?  theme.styles.inAppContentContainer : theme.styles.contentContainer}>
          {renderIntroBox()}  
          <div className="grid">
            {Object.keys(lessons).map((lessonId) => {
              const lesson = lessons[lessonId];

              return (
                <div key={lesson.id} className="clickable" style={theme.styles.lightBoxCell} onClick={() => onGoToTutorial(lesson)}>
                  <h1>{lesson.title}</h1>
                  <p syle={theme.styles.lightP}>{lesson.description}</p>
                </div>
              );
            })}
          </div>
          <div style={theme.styles.footer}>
              <div style={theme.styles.footerText}>Need more help or have questions? Emails us <a href="mailto:hello@roughups.com">hello@roughups.com</a> or ask on Twitter <a href="https://twitter.com/@roughups">@roughups</a>. We also love to hear your ideas for future features.</div>
          </div>
        </div>
    ); 
};