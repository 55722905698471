import React from "react";

const FEATURES = [
    {
        id: "share",
        title: "Share your creations",
        description: "Share your creations with short (optionally password protected) links"
    },
    {
        id: "screenRecording",
        title: "Screen  Walkthroughs",
        description: "Record your screen as you talk people through your creations"
    },
    {
        id: "camera",
        title: "Camera Components",
        description: "Capture photos from your user or design flows involving the camera"
    },
    {
        id: "fonts",
        title: "Create your own fonts",
        description: "Draw your own fonts"
    },
    {
        id: "templates",
        title: "Templates",
        description: "Create templates you can include on multiple screens (e.g. tab bars or menus)"
    },
    {
        id: "simpleAnimation",
        title: "Simple animations",
        description: "Create simple spinners"
    },
    {
        id: "logic",
        title: "Logic",
        description: "Create your own custom logic paths through your wireframes"
    }
];

export default({theme}) => {

    return (
        <div style={theme.styles.contentContainer}>
            <div style={theme.styles.primaryBox}>
            <h1>Upgrade to PRO and get all of this</h1>
            <p style={theme.styles.bigP}>Roughups PRO takes your wireframes above and beyond with things like sharing, screen recording, components & more. A full list of everything more you get with PRO is shown below...</p>
            </div>

            <div className="grid2">
                {FEATURES.map((feature) => {
                    return (
                        <div key={feature.id} style={theme.styles.lightBoxCell}>
                            <h1>{feature.title}</h1>
                            <p syle={theme.styles.lightP}>
                                {feature.description}
                            </p>
                        </div>
                    )
                })}
                
            </div>

            <div style={theme.styles.footer}>
                <div style={theme.styles.footerText}>
                    You can upgrade to PRO on either a monthly or yearly basis. All subscriptions are auto-renewing and you can cancel them in the normal way on Apple devices through your subscription settings.
                    For more see the full <a href="/terms">Terms & Conditions</a> and <a href="/privacy">Privacy Policy</a>.
                </div>
            </div>

        </div>
    );
};