import React, { useState, useEffect } from "react";

import ScreenFrame from "./ScreenFrame";
import Screen from "./Screen";
import FontServices from "../services/FontServices";
import { RecoilRoot } from "recoil";

const DEFAULT_SCALE = 0.6;

const TRANSITION_ANIMATIONS = {
    top: "screen-bottom-to-top",
    bottom: "screen-top-to-bottom",
    right: "screen-left-to-right",
    left: "screen-right-to-left",
    none: "screen-no-transition"
};

const DEFAULT_NEXT_SCREEN_ANIMATION = TRANSITION_ANIMATIONS.right;

export default({version, theme, fields, onChangeField, initialScreen}) => {

    var firstScreen = initialScreen;

    if (!firstScreen) {
        // Find the first screen
        if (version.definition.screensOrder) {
            firstScreen = version.definition.screens[version.definition.screensOrder[0]];
        } else {
            firstScreen = version.definition.screens[version.definition.first_screen];
        }
    }
    
    const [currentScreen, setCurrentScreen] = useState(firstScreen);
    const [currentScreenState, setCurrentScreenState] = useState(null);
    const [goingBack, setGoingBack] = useState(false);

    const [history, setHistory] = useState([]);

    useEffect(() => {
        FontServices.loadFont();
    }, []);

    const localStyles = {
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            userSelect: "none"
        },
        currentScreen: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999
        },
        previousScreen: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 998
        }
    };

    const onChangeScreen = (changeTo, hotspot) => {
        var transition = DEFAULT_NEXT_SCREEN_ANIMATION;

        if (hotspot.action && hotspot.action.transition) {
            if (TRANSITION_ANIMATIONS[hotspot.action.transition]) {
                transition = TRANSITION_ANIMATIONS[hotspot.action.transition];
            }
        }

        setHistory(history.concat([currentScreen]));
        setCurrentScreenState(transition);
        setCurrentScreen(changeTo);
    };

    const onGoBack = () => {
        if (history.length > 0) {
            // First do the animation
            setGoingBack(true);
            setCurrentScreenState("screen-right-to-left");
        }
    };

    const onAnimationEnd = (evt) => {
        if (currentScreenState) {
            setCurrentScreenState(null);

            if (goingBack) {
                setGoingBack(false);
                var updatedHistory = [].concat(history);
                setCurrentScreen(updatedHistory[history.length - 1]);
                updatedHistory.splice(history.length - 1, 1)
                setCurrentScreenState(null);
                setHistory(updatedHistory);
            }
        }
    };

    const renderPreviousScreen = () => {
        if (!history || history.length === 0) {
            return null;
        }

        return (
            <div style={localStyles.previousScreen}>
                <Screen
                    version={version}
                    theme={theme}
                    scale={DEFAULT_SCALE}
                    enableHotspots={true}
                    screen={history[history.length - 1]}
                    fields={fields}
                    onChangeField={onChangeField}
                    onChangeScreen={(changeTo, hotspot) => onChangeScreen(changeTo, hotspot)}
                    onGoBack={(hotspot) => onGoBack(hotspot)}
                />
            </div>
        );
    };

    return (
        <RecoilRoot>
            <div style={localStyles.container} onAnimationEnd={(evt) => onAnimationEnd(evt)}> 
                <ScreenFrame theme={theme} version={version} scale={DEFAULT_SCALE}>
                    {renderPreviousScreen()}
                    <div className={currentScreenState} style={localStyles.currentScreen}>
                        <Screen
                            version={version}
                            theme={theme}
                            scale={DEFAULT_SCALE}
                            enableHotspots={true}
                            screen={currentScreen}
                            fields={fields}
                            onChangeField={onChangeField}
                            onChangeScreen={(changeTo, hotspot) => onChangeScreen(changeTo, hotspot)}
                            onGoBack={(hotspot) => onGoBack(hotspot)}
                        />
                    </div>
                    
                </ScreenFrame>
            </div>
        </RecoilRoot>
    )
};