import React from "react";
import TopBar from "../components/TopBar";
import About from "./About";
import Upgrade from "./Upgrade";
import Terms from "./Terms";

import {
  Switch,
  Route
} from "react-router-dom";

import Learn from "./Learn";
import Lesson from "./Lesson";
import Privacy from "./Privacy";
import Pricing from "./Pricing";


const Home = function({theme}) {

  const localStyles = {
    outerContainer: {
      display: "flex",
      justifyContent: "center"
    },
    container: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        //justifyContent: "center",
        maxWidth: 1000,
        width: "80%"
    },
    centered: {
      textAlign: "center"
    },
    content: {
      paddingTop: 60,
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  };

  return (
    <div style={theme.createStyle([localStyles.outerContainer, theme.styles.background])}>
      <div style={localStyles.container}>
        <TopBar
          theme={theme}
        />

        <Switch>
          <Route path="/marketing" exact>
            <About
              theme={theme}
            />
          </Route>
          <Route path="/" exact>
            <About
              theme={theme}
            />
          </Route>
          <Route path="/learn" exact>
            <Learn
              theme={theme}
            />
          </Route>
          <Route path="/upgrade" exact>
            <Upgrade
              theme={theme}
            />
          </Route>
          <Route path="/privacy" exact>
            <Privacy
              theme={theme}
            />
          </Route>
          <Route path="/terms" exact>
            <Terms
              theme={theme}
            />
          </Route>
          <Route path="/pricing" exact>
            <Pricing
              theme={theme}
            />
          </Route>
          <Route path="/learn/:id">
            <Lesson
              theme={theme}
            />
          </Route>
        </Switch>
      </div>
    </div>
    
  );
};

export default Home;
