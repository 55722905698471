export default {

    isInApp(location) {
        if (location.search && location.search.indexOf("app=true") >= 0) {
            return true;
        }

        return false;
    },

    makeLink(link, location) {
        var createdLink = link;

        if (this.isInApp(location)) {
            createdLink = `${link}?app=true`;
        }

        return createdLink;
    } 

};