module.exports = {
    THEME_DARK: "dark",
    THEME_LIGHT: "light",

    ACTION_TYPE_SCREEN: "screen",
    ACTION_TYPE_BACK: "back",
    ACTION_TYPE_FIELD: "field",

    HOTSPOT_FIELD_TYPE_TOGGLE: "toggle",
    HOTSPOT_FIELD_TYPE_TEXT: "text",

    COMPONENT_TYPE_SIMPLE_ANIMATE: "simple_animate"
}