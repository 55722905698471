import Style from "./style";
import React, {useState, useEffect} from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Home from "./screens/Home";
import Roughup from "./screens/Roughup";
import ColorSchemeOverrideServices from "./services/ColorSchemeOverrideServices";
import Placeholder from "./screens/Placeholder";

export default function RoughupsApp() {
    const [theme, setTheme] = useState(null);

    useEffect(() => {
        const isDark = window.matchMedia('(prefers-color-scheme: dark)');
        
        if (window.localStorage.colorScheme) {
          setTheme(Style[window.localStorage.colorScheme]);
        } else {
          
          if (isDark.matches) {
              setTheme(Style.dark);
          } else {
              setTheme(Style.light);
          }

          isDark.addListener((event) => onColorSchemeChange(event));
        }
        
        ColorSchemeOverrideServices.setListener((e) => {
          onColorSchemeChange();
        })

        function onColorSchemeChange (e) {
          if (window.localStorage.colorScheme) {
            setTheme(Style[window.localStorage.colorScheme]);
          } else {
            if (e) {
              const newColorScheme = e.matches ? "dark" : "light";
              setTheme(Style[newColorScheme]);
            } else {
              if (isDark.matches) {
                setTheme(Style.dark);
              } else {
                  setTheme(Style.light);
              }
            }
            
          }
        }

    }, []);

    if (!theme) {
        return null;
    }

    return (
      <div style={theme.styles.background}>
        <Router>
          <Switch>
            <Route path="/placeholder" exact>
              <Placeholder theme={theme}/>
            </Route>
            <Route path="/" exact>
              <Home theme={theme}/>
            </Route>
            <Route path="/pricing" exact>
              <Home theme={theme}/>
            </Route>
            <Route path="/learn">
              <Home theme={theme}/>
            </Route>
            <Route path="/upgrade" exact>
              <Home
                theme={theme}
              />
            </Route>
            <Route path="/privacy" exact>
              <Home
                theme={theme}
              />
            </Route>
            <Route path="/terms" exact>
              <Home
                theme={theme}
              />
            </Route>
            <Route path="/:shortId">
              <Roughup theme={theme}/>
            </Route>
          </Switch>
        </Router>
      </div>
      
    )
}