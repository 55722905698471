import * as React from "react";

function MoreIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 62 62"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 0c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0zM17 26a6 6 0 100 12 6 6 0 000-12zm15 0a6 6 0 100 12 6 6 0 000-12zm15 0a6 6 0 100 12 6 6 0 000-12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default MoreIcon;

