// eslint-disable-next-line import/no-webpack-loader-syntax
import basics from "!!raw-loader!../config/md5/basics.md";

// eslint-disable-next-line import/no-webpack-loader-syntax
import toggles from "!!raw-loader!../config/md5/toggles.md";

// eslint-disable-next-line import/no-webpack-loader-syntax
import dataFields from "!!raw-loader!../config/md5/data_fields.md";



export default {
    "basics": {
        "id": "basics",
        "title": "The Basics",
        "content": basics,
        "video_id": "633087635?h=8db7bf1c4d",
        "description": "This lesson introduces you to the basic concepts in Roughups."
    },
    "toggles" :{
        "id": "toggles",
        "title": "Toggles",
        "video_id": "633087186?h=bfb5f2a9e4",
        "content": toggles,
        "description": "Make your own custom toggles & tick boxes."
    },
    "animations": {
        "id": "animations",
        "title": "Animations",
        "video_id": "633948611?h=ec1264b80b", 
        "description": "Build simple animations like spinners."
    },
    "boxes": {
        "id": "boxes",
        "title": "Text boxes",
        "video_id": "633088223?h=9c1af0e7a5",
        "content": dataFields,
        "description": "Create text boxes that users can write into and use that data in your flows."
    },
    "fonts": {
        "id": "fonts",
        "title": "Custom fonts",
        "description": "Build your own custom fonts and use them in your Roughups.",
        "video_id": "634735413?h=980adfa741"
    
    },
    "templates": {
        "id": "templates",
        "title": "Templates",
        "description": "Use templates to repeat designs like tab bars across screens.",
        "video_id": "633087766?h=8bd7470956"
    },
    "sharing": {
        "id": "sharing",
        "title": "Sharing",
        "description": "Share your Roughups on the web via a link so that anyone can view them.",
        "video_id": "634735319?h=f683dcd7ca"
    }
}