const { default: TakePictureServices } = require("./TakePictureServices");

const IF = "if";
const THEN = "then";
const DO = "do";
const THEN_DO = "then_do";
const ELSE = "else";
const ELSE_DO = "else_do"
const AND = "and";
const OR = "or";

const THEN_TYPES = {
    then: THEN,
    do: DO,
    else: ELSE,
    then_do: THEN_DO,
    else_do: ELSE_DO
};

const IF_TYPES = {
    if: IF,
    or: OR,
    and: AND
};

const CONDITIONS = [
    {id: IF, label: "IF", appearsAfter: [null], end: false},
    {id: DO, label: "DO", appearsAfter: [null], end: false},
    {id: THEN, label: "THEN", appearsAfter: [IF, AND, OR], end: false},
    {id: THEN_DO, label: "AND", appearsAfter: [THEN, THEN_DO, DO], end: false},
    {id: ELSE, label: "ELSE", appearsAfter: [THEN, THEN_DO], end: false},
    {id: ELSE_DO, label: "AND", appearsAfter: [ELSE, ELSE_DO], end: false},
    {id: AND, label: "AND", appearsAfter: [IF, AND, OR], end: false},
    {id: OR, label: "OR", appearsAfter: [IF, AND, OR], end: false}
];

const EQUALS = "==";
const NOT_EQUALS = "!=";
const GREATER_THAN = ">";
const LESS_THAN = "<";
const GREATER_THAN_OR_EQUAL = ">=";
const LESS_THAN_OR_EQUAL = "<=";

const OPERATORS = [
    {id: EQUALS, label: "=="},
    {id: NOT_EQUALS, label: "!="},
    {id: GREATER_THAN, label: ">"},
    {id: LESS_THAN, label: "<"},
    {id: GREATER_THAN_OR_EQUAL, label: ">="},
    {id: LESS_THAN_OR_EQUAL, label: "<="}
];

const OPERATOR_FUNCTIONS = {
    "==": (a, b) => {
        return a === b;
    },
    ">": (a, b) => {
        return a > b;
    },
    "<": (a, b) => {
        return a < b;
    },
    ">=": (a, b) => {
        return a >= b;
    },
    "<=": (a, b) => {
        return a <= b;
    },
    "!=": (a, b) => { //eslint-disable-next-line
        return a != b;
    }

}

const ASSIGN = "=";
const PLUS_EQUALS = "+=";
const MINUS_EQUALS = "-=";

const SET_OPERATORS = [
    {id: ASSIGN, label: ASSIGN},
    {id: PLUS_EQUALS, label: PLUS_EQUALS},
    {id: MINUS_EQUALS, label: MINUS_EQUALS}
];

const GOTO = "goto";
const SET = "set";
const TAKE_PHOTO = "take_photo";
const CLEAR_PHOTO = "clear_photo";

const ACTIONS = [
    {id: GOTO, label: "Go to"},
    {id: SET, label: "Set field"},
    {id: TAKE_PHOTO, label: "Take photo"},
    {id: CLEAR_PHOTO, label: "Clear photo"}
];

export default {

    IF: IF,
    AND: AND,
    THEN: THEN,
    OR: OR,
    ELSE: ELSE,
    EQUALS: EQUALS,
    NOT_EQUALS: NOT_EQUALS,
    GREATER_THAN: GREATER_THAN,
    LESS_THAN: LESS_THAN,
    GREATER_THAN_OR_EQUAL: GREATER_THAN_OR_EQUAL,
    LESS_THAN_OR_EQUAL: LESS_THAN_OR_EQUAL,
    CONDITIONS: CONDITIONS,
    OPERATORS: OPERATORS,
    SET_OPERATORS: SET_OPERATORS,
    ACTIONS: ACTIONS,
    ASSIGN: ASSIGN,
    PLUS_EQUALS: PLUS_EQUALS,
    MINUS_EQUALS: MINUS_EQUALS,
    GOTO: GOTO,
    SET: SET,

    OPERATOR_FUNCTIONS: OPERATOR_FUNCTIONS,

    async runLogic(hotspot, variables, onChangeVariable, onGoToScreen, onGoBack) {
        const logic = hotspot.logic;

        try {
            const ifResult = await this.runIfPart(variables, logic);

            if (ifResult) {
                // If result is true, find the thens to do
                await this.runThens(logic, variables, onChangeVariable, onGoToScreen, onGoBack);

            } else {
                // See if we have an else
                await this.runElses(logic, variables, onChangeVariable, onGoToScreen, onGoBack);
            }


        } catch (e) {
            // Could not run logic do nothing here
        }
    
    },

    async runThens(logic, variables, onChangeVariable, onGoToScreen, onGoBack) {
        // Find the then
        var inThen = false;

        for (const row of logic) {
            if (!row.condition || !row.condition.id) {
                // Missing a condition, break out
                return;
            }

            if (row.condition.id === ELSE) {
                // Once we hit else we are done
                return;
            }

            if (this.isThen(row.condition)) {
                inThen = true;
            }

            if (inThen) {
                await this.runAction(row, variables, onChangeVariable, onGoToScreen, onGoBack);
            }

        }
    },

    async runAction(row, variables, onChangeVariable, onGoToScreen, onGoBack) {
        if (!row.action || !row.action.id) {
            return;
        }

        if (row.action.id === SET) {
            await this.runSetAction(row, variables, onChangeVariable);
        } else if (row.action.id === GOTO) {
            await this.runGoTo(row, onGoToScreen);
        } else if (row.action.id === TAKE_PHOTO) {
            await this.runTakePhoto(row, onChangeVariable);
        } else if (row.action.id === CLEAR_PHOTO) {
            await this.runClearPhoto(row, onChangeVariable);
        }
    },

    async runGoTo(row, onGoToScreen) {
        if (row.goto && row.goto) {
            onGoToScreen(row.goto);
        }
    },

    convertValueToString(value, type) {
        if (type && type === "boolean") {
            return value;
        }

        var valueInString = "";

        if (value !== null && value !== undefined) {
            valueInString = "" + value;
        }

        return valueInString;
    },

    convertToNumberIfNumber(val) {
        try {
            if (val && !isNaN(val)) {
                if (val.indexOf(".") >= 0) {
                    return parseFloat(val);
                } else {
                    return parseInt(val);
                }
            } else {
                return val;
            }
        } catch (e) {
            return val;
        }
    },

    async runTakePhoto(row, onChangeVariable) {
        TakePictureServices.takePictureWithHotspot(row.variable.id, (imgData) => {
            onChangeVariable(row.variable.id, imgData);
        });
    },

    async runClearPhoto(row, onChangeVariable) {
        onChangeVariable(row.variable.id, null);
    },

    async runSetAction(row, variables, onChangeVariable) {
        if (!row.set_operator || !row.set_operator.id || !row.variable || !row.variable.id) {
            return;
        } 

        if (!row.value) {
            // We are setting variable to null/empty
            onChangeVariable(row.variable.id, null);
        }

        if (row.set_operator.id === ASSIGN) {
            // simply setting variable
            var valueInType = this.getValueInType(row.value.value, row.value.type, variables);

            onChangeVariable(row.variable.id, this.convertValueToString(valueInType, row.value.type));
        } else {
            // We are either doing += or -=
            // So we need to figure out if we are using numbers or strings
            if (!row.value.type || row.value.type === "string") {
                // Strings
                var newValue = "";
                var valueToAdd = row.value.value || "";

                if (variables[row.variable.id]) {
                    newValue = variables[row.variable.id];
                }

                if (row.set_operator.id === PLUS_EQUALS) {
                    newValue += valueToAdd;
                    onChangeVariable(row.variable.id, this.convertValueToString(newValue));
                } else if (row.set_operator.id === MINUS_EQUALS) {
                    // This doesn't make sense I don't think
                    // So do nothing!
                }

            } else if (row.value.type === "number") {
                // Numbers 
                // eslint-disable-next-line
                var newValue = 0;
                //eslint-disable-next-line
                var valueToAdd = this.getValueInType(row.value.value ? row.value.value : 0, row.value.type);

                if (variables[row.variable.id]) {
                    newValue = this.getValueInType(variables[row.variable.id], row.value.type);
                }

                if (row.set_operator.id === PLUS_EQUALS) {
                    newValue += valueToAdd;
                    onChangeVariable(row.variable.id, this.convertValueToString(newValue));
                } else if (row.set_operator.id === MINUS_EQUALS) {
                    newValue -= valueToAdd;
                    onChangeVariable(row.variable.id, this.convertValueToString(newValue));
                }
            } else if (row.value.type === "variable") {
                // With variable assign if the first variable is empty we just set to the second
                var val1 = this.convertToNumberIfNumber(variables[row.variable.id]);
                var val2 = this.convertToNumberIfNumber(variables[row.value.value.id]);

                if (!val1) {
                    if (!val2) {
                        return;
                    }

                    try {
                        if (!isNaN(val2)) {
                            val1 = 0;
                        } else {
                            val1 = "";
                        }
                    } catch (e) {
                        val1 = "";
                    }
                }

                if (row.set_operator.id === PLUS_EQUALS) {
                    //eslint-disable-next-line
                    var newValue = val1 += val2;
                    onChangeVariable(row.variable.id, newValue + "");
                }  else if (row.set_operator.id === MINUS_EQUALS) {
                    // eslint-disable-next-line
                    var newValue = val1 -= val2;
                    onChangeVariable(row.variable.id, newValue + "");
                } 


            } else {
                onChangeVariable(row.variable.id, row.value.value);
            }

        }
    },
 
 
    async runElses(logic, variables, onChangeVariable, onGoToScreen, onGoBack) {
        // Find the then
        var inElse = false;

        for (const row of logic) {
            if (!row.condition || !row.condition.id) {
                // Missing a condition, break out
                return;
            }

            if (row.condition.id === ELSE) {
                // Once we hit else we are done
                inElse = true;
            }

            if (inElse) {
                await this.runAction(row, variables, onChangeVariable, onGoToScreen, onGoBack);
            }

        }
    },

    getValueInType(value, type, variables) {
        if (type === "variable") {
            // Comparing two variables, return as is
            if (!variables) {
                // If we haven't passed the variables option, we already have the value
                // And want to return it pure as is
                return value;
            }

            return variables[value.id];
        }

        if (type === "boolean") {
            if (value) {
                return true;
            } 

            return false;
        }

        if (!value) {
            return null;
        }

        if (!type || type === "string") {
            return value;
        } else if (type === "number") {
            var numberValue = null;

            // Attempt to process into number
            if (value.indexOf(".") >= 0) {
                // process as float
                numberValue = parseFloat(value);
            } else {
                numberValue = parseInt(value);
            }

            if (isNaN(numberValue)) {
                return null;
            }

            return numberValue;
        }
    },

    async checkClause(variables, clause) {
        if (!clause.variable || !clause.variable.id || !clause.operator || !clause.operator.id) {
            // Something is missing, return false
            return {
                condition: clause.condition.id,
                result: false
            };
        }

        if (!clause.value) {
            // We are checking to see if variable is null or blank string
            if (!variables[clause.variable.id] || variables[clause.variable.id].length === 0) {
                return {
                    condition: clause.condition.id,
                    result: true
                };
            } else {
                return {
                    condition: clause.condition.id,
                    result: false
                };
            }
        }

        // We have all the bits we need
        var processedValue = this.getValueInType(clause.value.value, clause.value.type, variables);
        var variableValue = this.getValueInType(variables[clause.variable.id], clause.value.type);

        // TODO GET VALUE FROM VARIABLE IF COMPARING TO ONE
        const result = OPERATOR_FUNCTIONS[clause.operator.id](variableValue, processedValue);

        return {
            condition: clause.condition.id,
            result: result
        }

    },

    async runIfPart(variables, logic) {
        if (!logic || logic.length === 0 || !logic[0].condition || !logic[0].condition.id) {
            throw Error("No logic set");
        }

        if (!this.isIf(logic[0].condition)) {
            // If passes because there isn't an IF
            return true;
        }

        // We do have an if, lets look at each clause
        var clauseResults = [];

        for (const logicRow of logic) {
            if (!this.isIf(logicRow.condition)) {
                break;
            }

            // We have a valid clause to run
            clauseResults.push(await this.checkClause(variables, logicRow));
        }

        
        var endResult = clauseResults[0].result; // First result will always be the IF on line 1

        if (clauseResults.length > 1) {
            // Loop through remaining clause results
            clauseResults.forEach((clauseResult) => {
                if (clauseResult.condition === AND) {
                    endResult = endResult && clauseResult.result;
                } else if (clauseResult.condition === OR) {
                    endResult = endResult || clauseResult.result;
                }
            });
        }

        return endResult;
    },

    isIf(condition) {
        if (!condition || !condition.id) {
            return false;
        }

        if (IF_TYPES[condition.id]) {
            return true;
        }

        return false;
    },

    isThen(condition) {
        if (!condition || !condition.id) {
            return false;
        }

        if (THEN_TYPES[condition.id]) {
            return true;
        }

        return false;
    }


};