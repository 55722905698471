import fetch from "isomorphic-unfetch";

const STATUS_CODE_OK = 200;

var BASE_URL;

// eslint-disable-next-line
const REMOTE_ENV = {
    api: "https://api.roughups.com"
};

// eslint-disable-next-line
const LOCAL_ENV = {
    api: "http://localhost:8080"
};

var ENV_TO_USE = REMOTE_ENV;

if (window.location.href.indexOf("localhost") >= 0) {
    ENV_TO_USE = REMOTE_ENV;
}

ENV_TO_USE = REMOTE_ENV;

BASE_URL = ENV_TO_USE.api;

const APPLICATION_JSON_CONTENT_TYPE = "application/json";

export default {

    token: null,

    getBaseUrl() {
        return BASE_URL;
    },

    async createHeaders(alternativeBaseUrl) {
        if (alternativeBaseUrl) {
            return {
                "Content-Type": APPLICATION_JSON_CONTENT_TYPE
            };
        }

        var headers = {
            
        };

        return headers;
    },

    async get(url, alternativeBaseUrl) {
        var res;

        try {
            res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
                method: 'GET',
                headers: await this.createHeaders(alternativeBaseUrl)
            });

            return this.handleResponse(res);

        } catch (e) {
            console.log(e);
            throw Error("An unxpected error occurred", e);
        }

    },

    async handleResponse(res) {
        // Get data back
        const data = await res.json();
            
        if (res.status !== STATUS_CODE_OK) {
            if (Array.isArray(data)) {
                if (data.length > 0 && data[0].error) {
                    throw Error(data[0].error);
                }
            } else if (data.error) {
                throw Error(data.error);
            }

            throw Error("Unexpected Error")
        } else {
            return data;
        }
    },

    async post(url, body, alternativeBaseUrl) {
        var res;

        try {
            res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
                method: 'POST',
                headers: await this.createHeaders(alternativeBaseUrl),
                body: JSON.stringify(body)
            });

            return this.handleResponse(res);

        } catch (e) {
            throw Error("An unxpected error occurred");
        }
    },

    stringifyNoQuotes(obj) {
        var jsonStr = JSON.stringify(obj);
        // eslint-disable-next-line
        return jsonStr.replace(/\"([^(\")"]+)\":/g,"$1:"); 
    },

    stringifyAndEscape(obj) {
        var jsonStr = JSON.stringify(obj);
        return JSON.stringify(jsonStr);
    }
};