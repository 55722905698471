import React from "react";

import ScreenFrame from "./ScreenFrame";
import Screen from "./Screen";
import useWindowSize from "../hooks/useWindowSize";

const DEFAULT_SCALE = 0.35;
const SMALL_SCALE = 0.2;
const SMALL_WINDOW_SIZE = 600;

export default({theme, version, fields, onGoToScreen}) => {

    const windowSize = useWindowSize();

    const localStyles = {
        container: {
            paddingLeft: 30,
            paddingRight: 30,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        },
        screenHolder: {
            marginRight: 30,
            marginBottom: 30,
            cursor: "pointer"
        }
    };

    const getScreens = () => {
        if (version.definition.screensOrder) {
            return version.definition.screensOrder;
        }

        return Object.keys(version.definition.screens);
    };

    const getScale = () => {
        if (windowSize && windowSize.width < SMALL_WINDOW_SIZE) {
            return SMALL_SCALE;
        }

        return DEFAULT_SCALE;
    };

    return (
        <div style={localStyles.container}>
            {getScreens().map((screenId) => {
                const screen = version.definition.screens[screenId];
                return (
                    <div key={screenId} style={localStyles.screenHolder} onClick={() => onGoToScreen(screen)}>
                        <ScreenFrame theme={theme} version={version} scale={getScale()}>
                            <Screen
                                version={version}
                                theme={theme}
                                scale={getScale()}
                                enableHotspots={true}
                                disableHotspotInteractions={true}
                                onChangeScreen={() => {}}
                                onChangeField={() => {}}
                                screen={screen}
                                fields={fields}
                            />
                        </ScreenFrame>
                    </div>
                    
                )
            })}
        </div>
    );
};