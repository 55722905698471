import React from "react";
import Logo from "./Logo";
import { useSpring, animated } from "react-spring";
import Password from "./Password";

const WIDTH = 200;
const MAX_PROGRESS = 100;
const PROGRESS_BEFORE_UNKNOWN = 80;
const UNKNOWN_DURATION = 2000;
const PROGRESS_AFTER_UNKNOWN_DURATION = 8000;

function LoadingProgress({id, progress, requiresPassword, onPasswordEntered, theme, unknown, error}) {

    const getProgressWidth = () => {
        if (unknown) {
            return (MAX_PROGRESS / 100) * WIDTH;
        }

        return (progress / 100) * WIDTH;
    }

    const getDuration = () => {
        if (unknown) {
            return UNKNOWN_DURATION;
        } else if (progress >= PROGRESS_BEFORE_UNKNOWN) {
            return PROGRESS_AFTER_UNKNOWN_DURATION;
        } else {
            return undefined;
        }
    };

    const progressProps = useSpring({
        width: getProgressWidth(),
        backgroundColor: theme.colors.primary,
        height: 10,
        config: {
            duration: getDuration()
        }
    });
   

    const localStyles = {
        container: {
            position: "absolute",
            backgroundColor: theme.colors.background,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        },
        emptyBar: {
            width: WIDTH,
            height: 10,
            backgroundColor: theme.mode === "dark" ? theme.colors.logoBorder : theme.colors.light,
            overflow: "hidden",
            borderRadius: 14
        },
        logo: {
            width: 40,
            height: 40,
            marginBottom: 20,
            cursor: error ? "pointer" : undefined
        },
        sorry: {
            fontSize: 16,
            fontWeight: "700"
        }
    };
    
    const renderBarOrError = () => {
        if (error) {
            return (
                <div style={localStyles.sorry}>
                    Sorry, we couldn't find that
                </div>
            );
        }

        if (requiresPassword) {
            return (
                <Password
                    theme={theme}
                    id={id}
                    onPasswordEntered={onPasswordEntered}
                />
            );
        }

        return (
            <div style={localStyles.emptyBar}>
                <animated.div style={progressProps}></animated.div>
            </div>
        );

    }

    const onLogoClick = () => {
        window.location.href = "/";
    };

    return (
        <div style={localStyles.container}>
            <Logo 
                theme={theme}
                style={localStyles.logo} 
                animated={!error && !requiresPassword}
                onClick={error ? onLogoClick : undefined}/>
            {renderBarOrError()}
        </div>
    );
}

export default LoadingProgress;