import HttpServices from "./HttpServices";

export default {

    async getRoughupVersionAndUrls(shortId, password) {
        var params = {};

        if (password) {
			params.password = password;
        }
        
        const result = await HttpServices.post(`/play/${shortId}`, params);

        if (result.password_protected && !result.roughup_version) {
            return {
                passwordProtected: true
            };
        }
    
        return {
            roughupVersion: result.roughup_version,
            zipUploadUrl: result.zip_upload_url
        };

    }
};