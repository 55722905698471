import * as React from "react";

function PlayIcon(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 62 62"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31 0c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31C13.88 62 0 48.12 0 31 0 13.88 13.88 0 31 0zm-7.154 21.462V41.73l20.27-10.135-20.27-10.134z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default PlayIcon;

