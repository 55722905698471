import React from "react";

export default({videoId, theme}) => {
    if (!videoId) {
        return null;
    }

    const localStyles = {
        container: {
            // width: 300,
            // height: 300,
            // 0 0 0;
            //position: "relative"
            borderRadius: 12,
            //padding: 30,
            overflow: "hidden",
            backgroundColor: theme.colors.videoBackground,
            marginTop: 50,
            marginBottom: 50,
            
            boxShadow: theme.colors.topBarShadow
        },
        iFrame: {
            // position: "absolute",
            // top: 0,
            // left: 0,
            // right: 0,
            // bottom: 0,
            overflow: "hidden",
            borderRadius: 12,
            //width: "100%",
            //margin: 30,
        }
    }

    return (
        <div className="lesson-video-container" style={localStyles.container}>
        <iframe className="lesson-video-iframe" src={`https://player.vimeo.com/video/${videoId}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen style={localStyles.iFrame} title="Roughups Demo">
            </iframe>
        </div>
    );
};