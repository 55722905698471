import React from "react";

import RoughupServices from "../services/RoughupServices";
import { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import ZipServices from "../services/ZipServices";
import Main from "../components/Main";

const INCORRECT_PASSWORD_ERROR = "Incorrect password";

const Version = ({theme}) => {
    const [loading, setLoading] = useState(true);
    const [roughupVersion, setRoughupVersion] = useState(null);
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState(null);
    const [passwordProtected, setPasswordProtected] = useState(false);
    const [unknownProgress, setUnknownProgress] = useState(false);
    
    const { shortId } = useParams();
    const password = window.sessionStorage[shortId];

    const id = shortId;

    useEffect(() => {
        const preloadZip = async() => {
            setError(null);
            
            try {
                const result = await RoughupServices.getRoughupVersionAndUrls(id, password);

                if (result.passwordProtected && !result.roughupVersion) {
                    setPasswordProtected(true);
                    setLoading(false);
                } else {
                    setRoughupVersion(result.roughupVersion);
                    await ZipServices.extractRemoteFile(result.roughupVersion, result.zipUploadUrl, theme.mode, (evt) => {
                        if (evt.unknown) {
                            setUnknownProgress(true);
                        } else {
                            const progress = (evt.loaded / evt.total) * 100;
                            setProgress(progress);
                        }
                    });
        
                    setLoading(false);
                }
                
            } catch (e) {
                if (e.message === INCORRECT_PASSWORD_ERROR) {
                    //setLoading(false);
                    setPasswordProtected(true);
                } else {
                    console.error(e)
                    setError(e);
                }
                
            }
            
        }
        setLoading(true);
        preloadZip();
    }, [theme, id, password]);

    const onPasswordEntered = (enteredPassword) => {
        // Set session storage and reload page
        window.sessionStorage[id] = enteredPassword;
        window.location.reload();
    };

    return (
        <Main
            id={id}
            version={roughupVersion}
            progress={progress}
            unknownProgress={unknownProgress}
            requiresPassword={passwordProtected}
            loading={loading}
            error={error}
            onPasswordEntered={(password) => onPasswordEntered(password)}
            theme={theme}
        />   
    );
}

export default Version;