import React, {useState} from "react";
import ZipServices from "../services/ZipServices";
import ScreenServices from "../services/ScreenServices";
import Hotspots from "./Hotspots";

function Screen({theme, version, scale, enableHotspots, disableHotspotInteractions, screen, onChangeScreen, onGoBack, fields, onChangeField}) {    

    const screenSize = ScreenServices.getScreenSize(version, scale);

    const [showHotspotLocations, setShowHotspotLocations] = useState(false);

    const localStyles = {
        overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 999
        },
        imageFrame: {
            position: "relative",
            width: screenSize.width,
            height: screenSize.height,
            overflow: "hidden",
            userSelect: "none",
            backgroundColor: theme.colors.white
        },
        image: {
            width: screenSize.width,
            height: screenSize.height,
            backgroundColor: "transparent",
            overflow: "hidden",
            userSelect: "none",
            position: "absolute",
            top: 0,
            left: 0
        }
    };

    if (!ZipServices.getCachedImage(screen.id, theme.mode)) {
        console.log("Couldn't find image");
    }

    const changeScreen = (changeTo, hotspot) => {
        if (changeTo.id) {
            const foundScreen = version.definition.screens[changeTo.id];

            if (!foundScreen) {
                console.log("Couldn't find screen");
            }
    
            onChangeScreen(foundScreen, hotspot);
        } else if (changeTo.method && changeTo.method === "back") {
            onGoBack(hotspot);
        }
        
    };

    const renderOverlay = () => {
        if (!disableHotspotInteractions) {
            return null;
        }

        return (
            <div style={localStyles.overlay}></div>
        );
    };

    return (
       
        <div style={localStyles.imageFrame}>
            <Hotspots
                underlay={true}
                version={version}
                theme={theme}
                hotspots={screen.hotspots}
                scale={scale}
                enabled={enableHotspots}
                disableHotspotInteractions={disableHotspotInteractions}
                fields={fields}
                showHotspotLocations={showHotspotLocations}
                onChangeShowHotspotLocations={(on) => setShowHotspotLocations(on)}
                onChangeField={onChangeField}
                onChangeScreen={(changeToId, hotspot) => changeScreen(changeToId, hotspot)}
            />
            <img 
                alt="Screen"
                src={ZipServices.getCachedImage(screen.id)}
                style={localStyles.image}/>
            <Hotspots
                theme={theme}
                version={version}
                hotspots={screen.hotspots}
                scale={scale}
                enabled={enableHotspots}
                disableHotspotInteractions={disableHotspotInteractions}
                fields={fields}
                showHotspotLocations={showHotspotLocations}
                onChangeShowHotspotLocations={(on) => setShowHotspotLocations(on)}
                onChangeField={onChangeField}
                onChangeScreen={(changeToId, hotspot) => changeScreen(changeToId, hotspot)}
            />
            {renderOverlay()}
        </div>


    )
}



export default Screen;