module.exports = {
    AUTO: "auto",
    DARK: "dark",
    LIGHT: "light",

    listener: null,

    setListener(listener) {
        this.listener = listener;
    },

    changeColorSchemeType() {
        if (this.listener) {
            this.listener();
        }
    }

};