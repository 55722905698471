import React, {useState} from "react";
import ColorSchemeOverrideServices from "../services/ColorSchemeOverrideServices";

export default({theme}) => {

    const [currentScheme] = useState(window.localStorage.colorScheme || ColorSchemeOverrideServices.AUTO);

    const localStyles = {
        container: {
            display: "flex",
            flexDirection: "row"
        },
        circle: {
            cursor: "pointer",
            width: 20,
            height: 20,
            borderRadius: 20,
            borderColor: theme.colors.light,
            borderWidth: 2,
            borderStyle: "solid",
            marginRight: 4,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 12
        },
        circleOn: {
            borderColor: theme.colors.primary
        },
        dark: {
            backgroundColor: theme.colors.alwaysBlack
        },
        auto: {
            color: `${theme.colors.black} !important`
        },
        light: {
            backgroundColor: theme.colors.alwaysWhite
        }
    };

    const onSelectSchemeType = (evt, schemeType) => {
        if (schemeType === ColorSchemeOverrideServices.AUTO) {
            delete window.localStorage.colorScheme;
        } else {
            window.localStorage.colorScheme = schemeType;
        }
 
        ColorSchemeOverrideServices.changeColorSchemeType();
    }

    const renderCircle = (type) => {
        var circleStyles;

        if ((currentScheme && currentScheme === type) || (!currentScheme && type === ColorSchemeOverrideServices.AUTO)) {
            circleStyles = theme.createStyle([localStyles.circle, localStyles[type], localStyles.circleOn]);
        } else {
            circleStyles = theme.createStyle([localStyles.circle, localStyles[type]]);
        }

        return (
            <div style={circleStyles} onClick={(evt) => onSelectSchemeType(evt, type)}>
                {type === ColorSchemeOverrideServices.AUTO ? "A" : null} 
            </div>
        );
    };

    return (
        <div style={localStyles.container}>
            {renderCircle(ColorSchemeOverrideServices.AUTO)}
            {renderCircle(ColorSchemeOverrideServices.LIGHT)}
            {renderCircle(ColorSchemeOverrideServices.DARK)}
        </div>
    );

};