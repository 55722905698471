import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import TakePictureServices from "../services/TakePictureServices";

const ROUND = "round";

export default({ theme, fields, fieldId, hotspot, scaledCoordinates, disableHotspotInteractions }) => {    
    if (disableHotspotInteractions || !hotspot.componentType || hotspot.componentType !== "photo") {
        return null;
    }

    const camera = useRef(null);

    useEffect(() => {
        if (hotspot.fieldName) {
            TakePictureServices.setCameraListener(hotspot.fieldName, (cb) => {
                takePicture(cb);
            });
        }
    // eslint-disable-next-line
    }, [hotspot]);

    const takePicture = async (cb) => {
        if (camera && camera.current) {
			// We can take a pic
			const imgSrc = camera.current.getScreenshot(scaledCoordinates.width, scaledCoordinates.height);
			cb(imgSrc);
		}
    }

    const localStyles = {
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: "hidden",
            backgroundColor: theme.colors.white,
            borderRadius: hotspot.shape && hotspot.shape === ROUND ? "50%" : null
        },
        img: {
            position: "absolute",
            top: 0,
            width: scaledCoordinates.width,
            right: 0,
            height: scaledCoordinates.height,
            objectFit: "cover",
            //transform: "scale(0.5) translateX(-50%) translateY(-50%)"
            //transform: "scaleX(-1)"
        },
        webcam: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height,
            objectFit: "cover",
            borderRadius: hotspot.shape && hotspot.shape === ROUND ? "50%" : null
        }
    };

    const renderCamera = () => {
        const videoConstraints = {
            width: scaledCoordinates.width * 2,
            height: scaledCoordinates.height * 2,
            facingMode: "user"
        };

        return (
            <Webcam
                ref={camera}
                style={localStyles.webcam}
                mirrored={true}
                audio={false}
                imageSmoothing={true}
                forceScreenshotSourceSize={true}
                screenshotQuality={1}
                width={scaledCoordinates.width}
                height={scaledCoordinates.height}
                videoConstraints={videoConstraints}
            />
        );
    };

    const renderPhoto = () => {
        return (
            <img 
                alt="Camera"
                src={fields[fieldId]}
                style={localStyles.img}
            />
        )
    };

    var content;

    if (fields && fields[fieldId]) {
        content = renderPhoto();
    } else {
        content = renderCamera();
    }
    
    return (
        <div style={localStyles.container}>
            {content}
        </div>
    );
};