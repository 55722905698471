import React from "react";
import Hotspot from "./Hotspot";

export default({theme, version, isComponent, component, underlay, enabled, disableHotspotInteractions, hotspots, scale, onChangeScreen, fields, showLocationOverride, showHotspotLocations, onChangeShowHotspotLocations, onChangeField}) => {
    const localStyles = {
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            userSelect: "none",
            // NOTE: Change the below from 0 to null
            zIndex: underlay ? null : (isComponent ? 3 : 2)
        }
    };

    if (!enabled || !hotspots || Object.keys(hotspots).length === 0) {
        return null;
    }

    const getHostpots = () => {
        return Object.values(hotspots);
    };

    const onClick = (evt) => {
        const isScreen = evt.target.dataset.screen || false;

        if (!disableHotspotInteractions && isScreen) {
            onChangeShowHotspotLocations(!showHotspotLocations);
        }
    };

    const changeScreen = (changeTo, hotspot) => {
        onChangeShowHotspotLocations(false);
        onChangeScreen(changeTo, hotspot);
    };
    
    return (
        <div data-screen={true} style={localStyles.container} onClick={(evt) => onClick(evt)}>
            {getHostpots().map((hotspot) => {
                return (
                    <Hotspot
                        key={hotspot.id}
                        withinComponent={isComponent}
                        component={component}
                        enabled={enabled}
                        disableHotspotInteractions={disableHotspotInteractions}
                        version={version}
                        underlay={underlay}
                        hotspot={hotspot}
                        theme={theme}
                        scale={scale}
                        showLocation={(!disableHotspotInteractions) && (showLocationOverride || showHotspotLocations)}
                        onChangeShowHotspotLocations={onChangeShowHotspotLocations}
                        showHotspotLocations={showHotspotLocations}
                        fields={fields}
                        onChangeField={onChangeField}
                        onChangeScreen={(changeTo) => changeScreen(changeTo, hotspot)}
                    />
                )
            })}
        </div>
    );
};