import React from "react";
import HotspotInput from "./HotspotInput";
import HotspotToggle from "./HotspotToggle";
import HotspotPhoto from "./HotspotPhoto";
import HotspotSimpleAnimate from "./HotspotSimpleAnimate";
import HotspotComponent from "./HotspotComponent";
import LogicServices from "../services/LogicServices";

const HOTSPOT_ACTION_CHANGE_SCREEN = "screen";
const HOTSPOT_ACTION_BACK = "back";

export default({theme, version, underlay, enabled, disableHotspotInteractions, hotspot, scale, showLocation, onChangeShowLocation, onChangeShowHotspotLocations, onChangeScreen, fields, onChangeField, withinComponent}) => {
    
    const isUnderlayComponent = () => {
        if (withinComponent) {
            return false;
        }
        
        if ((hotspot.componentType && hotspot.componentType === "photo") || hotspot.componentId) {
            return true;
        }

        return false;
    };

    if (underlay && !isUnderlayComponent()) {
        return null;
    } else if (!underlay && isUnderlayComponent()) {
        return null;
    }

    const normalizeCoordinates = () => {
        // Makes sure smallest coordinate is first in both directions
        var normalizedCoordinates = {};

        // First for x
        if (hotspot.coordinates.x <= hotspot.coordinates.x2) {
            normalizedCoordinates.x = hotspot.coordinates.x;
            normalizedCoordinates.x2 = hotspot.coordinates.x2;
        } else {
            normalizedCoordinates.x = hotspot.coordinates.x2;
            normalizedCoordinates.x2 = hotspot.coordinates.x;
        }

        // Now for y
        if (hotspot.coordinates.y <= hotspot.coordinates.y2) {
            normalizedCoordinates.y = hotspot.coordinates.y;
            normalizedCoordinates.y2 = hotspot.coordinates.y2;
        } else {
            normalizedCoordinates.y = hotspot.coordinates.y2;
            normalizedCoordinates.y2 = hotspot.coordinates.y;
        }

        return normalizedCoordinates;
    }

    const normalizedCoordinates = normalizeCoordinates();
    
    var scaledCoordinates = {
        x: normalizedCoordinates.x * scale,
        y: normalizedCoordinates.y * scale,
        width: (normalizedCoordinates.x2 * scale) - (normalizedCoordinates.x * scale),
        height: (normalizedCoordinates.y2 * scale) - (normalizedCoordinates.y * scale)
    }

    const localStyles = {
        container: {
            backgroundColor: showLocation && !(hotspot.componentId || hotspot.componentType) ? theme.colors.transparentPrimary : null,
            position: "absolute",
            cursor: "pointer",
            userSelect: "none",
            top: scaledCoordinates.y,
            left: scaledCoordinates.x,
            width: scaledCoordinates.width,
            height: scaledCoordinates.height,
            overflow: hotspot.componentType ? null : "hidden"
        }
    };


    const onClick = (evt) => {
        if (disableHotspotInteractions) {
            return;
        }

        evt.stopPropagation();
        onChangeShowHotspotLocations(false);

        if (hotspot.action) {
            if (hotspot.action.type === HOTSPOT_ACTION_CHANGE_SCREEN) {
                onChangeScreen({
                    id: hotspot.action.id
                });
            } else if (hotspot.action.type === HOTSPOT_ACTION_BACK) {
                onChangeScreen({
                    method: HOTSPOT_ACTION_BACK
                })
            } else if (hotspot.logic) {
                LogicServices.runLogic(
                    hotspot, 
                    fields, 
                    onChangeField, 
                    (screen) => {
                        onChangeScreen({
                            id: screen
                        });
                    }, 
                    () => onChangeScreen({method: HOTSPOT_ACTION_BACK}));
            }
        }

    }

    const getFieldId = () => {
        return hotspot.fieldName || hotspot.id;
    };
    
    return (
        <div data-hotspot={true} style={localStyles.container} onClick={(evt) => onClick(evt)}>
            <HotspotInput
                hotspot={hotspot}
                theme={theme}
                scale={scale}
                fields={fields}
                disableHotspotInteractions={disableHotspotInteractions}
                fieldId={getFieldId()}
                onChange={(value) => onChangeField(getFieldId(), value)}
            />
            <HotspotToggle
                hotspot={hotspot}
                theme={theme}
                scale={scale}
                fields={fields}
                fieldId={getFieldId()}
                disableHotspotInteractions={disableHotspotInteractions}
                scaledCoordinates={scaledCoordinates}
                onChange={(value) => onChangeField(getFieldId(), value)}
            />
            <HotspotPhoto
                hotspot={hotspot}
                theme={theme}
                scale={scale}
                fields={fields}
                fieldId={getFieldId()}
                disableHotspotInteractions={disableHotspotInteractions}
                scaledCoordinates={scaledCoordinates}
                onChange={(value) => onChangeField(getFieldId(), value)}
            />
            <HotspotSimpleAnimate
                hotspot={hotspot}
                theme={theme}
                disableHotspotInteractions={disableHotspotInteractions}
                scale={scale}
                scaledCoordinates={scaledCoordinates}
            />
            <HotspotComponent
                hotspot={hotspot}
                theme={theme}
                version={version}
                scale={scale}
                enabled={enabled}
                disableHotspotInteractions={disableHotspotInteractions}
                scaledCoordinates={scaledCoordinates}
                showLocation={showLocation}
                onChangeShowLocation={onChangeShowLocation}
                onChangeShowHotspotLocations={onChangeShowHotspotLocations}
                onChangeScreen={onChangeScreen}
                fields={fields}
                onChangeField={onChangeField}
            />

        </div>
    );
}