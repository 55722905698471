import React from "react";



export default({theme}) => {

    const FEATURES = [
      {
        title: "Draw your ideas to life",
        description: "Grab your Apple Pencil and draw your ideas to life (whatever they may be). We make it ridiculously simple to create interactive wifreames straight from your iPad. ",
        image: theme.images.screenshots.ideas,
      },
      {
        title: "Connect screens",
        description: "Easily connect screens to make them interactive. Simply drag out your hotspot areas and add new screens or connect to existing ones.",
        image: theme.images.screenshots.connect,
        lesson: "basics"
      },
      {
        title: "Create text fields",
        description: "Create editable text fields that you can really enter text into",
        image: theme.images.screenshots.textField,
        lesson: "boxes"
      },
      {
        title: "Create custom toggles",
        description: "Create custom drawn toggle fields, for example radio buttons or checkboxes",
        image: theme.images.screenshots.toggle,
        lesson: "toggles"
      },
      {
        title: "Add camera components",
        description: "You can even add camera components to your wireframes for added realism.",
        image: theme.images.screenshots.camera
      },
      {
        title: "Add template sections",
        description: "Sometimes you'll need to create repeated sections like tab bars. Roughups lets you create templates for this.",
        image: theme.images.screenshots.templates,
        lesson: "templates"
      },
      
      {
        title: "Make your own fonts",
        description: "Create your own hand drawn fonts for your wireframes ",
        image: theme.images.screenshots.fonts,
        lesson: "fonts"
      },
      {
        title: "Use advanced logic",
        description: "Take your wireframes to the next level with our advanced logic editor. Whether its creating incrementing fields or checking user input, we've got you covered.",
        image: theme.images.screenshots.logic
      },
      {
        title: "Share your creations",
        description: "Share your wireframes on the web so that anyone can view and try them (with optional password protection)",
        image: theme.images.screenshots.share,
        lesson: "sharing"
      },
      {
        title: "Create for different devices",
        description: "Choose from 3 different screen sizes for your projects - Watch, Phone, Tablet/Desktop",
        image: theme.images.screenshots.screenSizes,
      },
      {
        title: "Record interactive walkthroughs",
        description: "Record interactive walkthroughs of your wireframes, highlighting key parts and optionally recording your face too",
        image: theme.images.screenshots.screenRecording,
      },
    ];

    const localStyles = {
      description: {
        fontSize: 16,
        lineHeight: "1.5em",
        marginTop: 10
      },
      linksHolder: {
        marginTop: 10
      }
    };

    const renderLessonLink = (feature) => {
      if (!feature.lesson) {
        return null;
      }

      return (
        <div style={localStyles.linksHolder}>
          <a href={`/learn/${feature.lesson}`}>Learn More</a>
        </div>
      );
    }
    
    return (
        <div style={theme.styles.contentContainer}>
          <div style={theme.styles.primaryBox}>
            <h1>Wireframing and prototyping on your iPad with Apple Pencil</h1>
            <p style={theme.styles.bigP}>Roughups lets you create hand drawn wireframes and easily turn them into more than just clickable prototypes</p>
          </div>
          <div className="grid">
            
            {FEATURES.map((feature, index) => {
              return (
                <div key={index} style={theme.styles.lightBoxCell}>
                  <img 
                    alt="Screenshot"
                    className="screenshot"
                    src={feature.image} 
                    />
                  <h1>{feature.title}</h1>
                  <p style={localStyles.description}>{feature.description}</p>
                  {renderLessonLink(feature)}
                </div>
              );
            })}
            

          </div>
        </div>
    ); 
};