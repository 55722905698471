export default {

    activeCameraListeners: {},

    setCameraListener(hotspotId, fn) {
        this.activeCameraListeners[hotspotId] = fn;
    },

    takePictureWithHotspot(hotspotId, cb) {
        if (this.activeCameraListeners[hotspotId]) {
            try {
                this.activeCameraListeners[hotspotId]((uri) => {
                    cb(uri);
                });
            } catch (e) {
                console.log("Could not take picture", e);
            }
        }
    }

};