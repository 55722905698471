import React, {useState} from "react";
import Logo from "./Logo";

import PlayIcon from "../svgs/PlayIcon";
import AllIcon from "../svgs/AllIcon";
import MoreIcon from "../svgs/MoreIcon";
import PlayerMenu from "./PlayerMenu";

const SIZE = 26;

export default({theme, hash, onPrint}) => {

    const ICONS = [
        {id: "player", title: "Play", render: () => <PlayIcon width={SIZE} height={SIZE}/>, hash: "", link: ""},
        {id: "all", title: "All Screens", render: () => <AllIcon width={SIZE} height={SIZE}/>, hash: "#all", link: "all"},
        {id: "more", title: "More", render: () => <MoreIcon width={SIZE} height={SIZE}/>}
    ];

    const [showingMenu, setShowingMenu] = useState(false);

    const localStyles = {
        container: {
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
            marginBottom: 30,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
        },
        leftBar: {
            height: 60,
            paddingLeft: 30,
            paddingRight: 30,
            backgroundColor: theme.colors.white,
            borderRadius: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: theme.colors.playerBarShadow
        },
        rightBar: {
            height: 60,
            paddingLeft: 30,
            paddingRight: 30,
            backgroundColor: theme.colors.white,
            borderRadius: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: theme.colors.playerBarShadow
        },
        logo: {
            width: 30,
            height: 30
        },
        iconImage: {
            width: 34,
            height: 34
        },
        icon: {
            cursor: "pointer",
            color: "#E5E4E4",
            paddingLeft: 5,
            paddingRight: 5,
            transition: "all 0.3s ease-in-out"
        },
        iconOn: {
            opacity: 1,
            color: theme.colors.primary
        },
        icons: {
            height: 60,
            userSelect: "none",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",   
        }
    };

    return (
        <div style={localStyles.container} className="no-print">
            <div style={localStyles.leftBar}>
                <Logo 
                    style={localStyles.logo} 
                    theme={theme}/>
            </div>
            <div style={localStyles.rightBar}>
                <div style={localStyles.icons}>
                    {ICONS.map((icon) => {
                        var iconStyle = localStyles.icon;

                        if ((icon.hash === undefined && showingMenu) || icon.hash === hash) {
                            iconStyle = theme.createStyle([localStyles.icon, localStyles.iconOn]);
                        }

                        var onClick;
                        
                        if (icon.link !== undefined) {
                            onClick = () => {
                                window.location.hash = icon.link
                            };
                        } else {
                            onClick = () => {
                                setShowingMenu(!showingMenu);
                            }
                        }

                        return (
                            <div key={icon.id} onClick={() => onClick()} style={iconStyle}>
                                {icon.render()}
                            </div>
                        );
                    })}
                </div>
            </div>
            
            <PlayerMenu
                theme={theme}
                visible={showingMenu}
                onPrint={onPrint}
                onClose={() => setShowingMenu(false)}
            />
            
        </div>
    )
}