import React from "react";
import ColorSchemeSelector from "./ColorSchemeSelector";

export default({theme, visible, onClose, onPrint}) => {
    if (!visible) {
        return null;
    }

    const getMenuItems = () => {
        var items = [];

        if (navigator.canShare) {
            items.push({id: "share", label: "Share", onClick: () => {
                navigator.share({
                    title: "Roughup",
                    text: "Check out this Roughup",
                    url: window.location.href
                })
            }});
        }

        items = items.concat([
            {id: "print", label: "Print", onClick: () => {
                onPrint();
            }},
            {id: "colorMode", label: "Color Mode", render: () => {
                return (
                    <ColorSchemeSelector
                        theme={theme}
                    />
                )
            }}
        ]);
        
        return items;
    };

    const menuItems = getMenuItems();

    const localStyles = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "transparent",
            zIndex: 99999
        },
        popup: {
            backgroundColor: theme.colors.white,
            position: "fixed",
            boxShadow: theme.colors.playerBarShadow,
            top: 110,
            right: 40,
            borderRadius: 16,
            width: 200
        },
        item: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontWeight: "800",
            color: theme.colors.black,
            fontSize: 14,
            paddingLeft: 15,
            paddingRight: 15,
            paddingTop: 10,
            paddingBottom: 10,
            borderBottomColor: theme.colors.light,
            borderBottomWidth: 1,
            borderBottomStyle: "solid"
        },
        lastItem: {
            borderBottomStyle: "none"  
        },
        triangle: {
            position: "absolute",
            top: -15,
            right: 30,
            width: 0,
            height: 0,
            borderStyle: "solid",
            borderWidth: "0 15px 26.0px 15px",
            borderColor: `transparent transparent ${theme.colors.white} transparent`
        }
    };

    const onOverlayClick = (evt) => {
        onClose();
    };
    
    const renderItemContent = (item) => {
        if (!item.render) {
            return null;
        }

        return item.render();
    };

    return (
        <div style={localStyles.overlay} onClick={(evt) => onOverlayClick(evt)}>
            <div style={localStyles.popup}>
                <div style={localStyles.triangle}></div>

                <div style={localStyles.items}>
                    {menuItems.map((item, index) => {
                        var itemStyle = localStyles.item;

                        if (index === menuItems.length - 1) {
                            itemStyle = theme.createStyle([localStyles.item, localStyles.lastItem])
                        }

                        return (
                            <div key={item.id} style={itemStyle} onClick={item.onClick ? () => item.onClick() : () => {}}>
                                <div>{renderItemContent(item)}</div>
                                <div className={item.render ? null : "primary-hover"}>{item.label}</div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    );
};