import React from "react";
import ZipServices from "../services/ZipServices";

export default({hotspot, disableHotspotInteractions, fields, fieldId, onChange, theme, scaledCoordinates}) => {
    if (!(hotspot.action && hotspot.action.type === "field")) {
        return null;
    }

    if (!hotspot.fieldType || hotspot.fieldType !== "toggle") {
        return null;
    }

    const localStyles = {
        container: {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        toggleImage: {
            width: scaledCoordinates.width,
            height: scaledCoordinates.height
        }
    }

    const renderTick = () => {
        if (!fields[fieldId]) {
            return null;
        }

        return (
            <img
                alt="Toggle on"
                src={ZipServices.getCachedImage(hotspot.id, theme.mode)}
                style={localStyles.toggleImage}
                />
        )
    }

    const onToggle = disableHotspotInteractions ? null : () => {
        if (!hotspot.readOnly && !disableHotspotInteractions) {
            onChange(!fields[fieldId]);
        }
    };

    return (
        <div style={localStyles.container} onClick={onToggle}>
            {renderTick()}
        </div>
    )
}