const DB_NAME = "roughups";
const ZIPS_STORE = "zips";
const LAST_ACCESSED_DATES = "last_accessed";

const MILLIS_IN_MINUTES = 1000 * 60;
const CACHE_LIFETIME_MINUTES = 1;//24 * 60;

const WAIT_FOR_INDEXED_DB_DELAY = 1000;

export default {

    db: null,
    request: null,
    completed: false,

    async connect() {
        this.db = await this.connectToDb();
    },

    async wait(ms) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    },

    async dbTimeout(noTimeout) {
        // NOTE This only seems to be needed in Safari (specifically iOS 14.6)
        // There seems to be a bug where indexeddb will not open correctly or initiate
        // if a new link is clicked
        // This is a temporary workaround so that if the DB doesn't open after 1s, we force
        // reload the page which seems to fix it
        if (!noTimeout) {
            await this.wait(WAIT_FOR_INDEXED_DB_DELAY);

            if (!this.completed) {
                window.location.reload();
            }
        }
        
    },

    async connectToDb(noTimeout) {
        return new Promise((resolve, reject) => {
            var request = indexedDB.open(DB_NAME,1);
            this.dbTimeout(noTimeout);
    
            request.onerror = function(event) {
                reject("Couldn't create Roughups datastore");
            };
    
            request.onblocked = function(event) {
                
            };

            request.onversionchange = function(event) {
               
            }

            request.onsuccess = (event) => {
                this.completed = true;
                const db = event.target.result;
                resolve(db);
            };

            request.onupgradeneeded = function(event) { 
                var db = event.target.result;
                db.createObjectStore(ZIPS_STORE, { keyPath: "id" }); 
                
            };
        });
        
    },

    async keyExists(id) {
        return new Promise((resolve, reject) => {
            var tx = this.db.transaction(ZIPS_STORE, "readwrite");
            var store = tx.objectStore(ZIPS_STORE);
            var req = store.openCursor(id);
            
            req.onsuccess = function(e) {
              var cursor = e.target.result; 
              if (cursor) { // key already exist
                 resolve(true);
              } else { // key not exist
                 resolve(false);
              }
            };

            req.onerror = (e) => {
                resolve(false);
            }
        });
    },

    async getZip(id) {
        this.updateLastAccessed(id);

        return new Promise((resolve, reject) => {
            var tx = this.db.transaction(ZIPS_STORE, "readwrite");
            var store = tx.objectStore(ZIPS_STORE);
            
            var request = store.get(id);
            
            request.onsuccess = () => {
                resolve(request.result.zip);
            }

            request.onerror = (e) => {
                console.error(e);
                reject();
            }
        });
    },

    async updateLastAccessed(id) {
        var lastAccessedDates = {};

        if (localStorage[LAST_ACCESSED_DATES]) {
            try {
                lastAccessedDates = JSON.parse(localStorage[LAST_ACCESSED_DATES]);
            } catch (e) {
                // Do nothing
                console.erorr(e);
            }
        }

        lastAccessedDates[id] = new Date();
        localStorage[LAST_ACCESSED_DATES] = JSON.stringify(lastAccessedDates);

        await this.clearOldCache();
    },

    async clearOldCache() {
        // Loop through last accessed dates and clear any old ones
        var lastAccessedDates = JSON.parse(localStorage[LAST_ACCESSED_DATES]);

        for (const id of Object.keys(lastAccessedDates)) {
            const timeDifference = (new Date().getTime() - new Date(lastAccessedDates[id]).getTime()) / MILLIS_IN_MINUTES;
            
            // Is this old?
            if (timeDifference > CACHE_LIFETIME_MINUTES) {
                // Delete
                await this.deleteZip(id);
                delete lastAccessedDates[id];
            }
        }

        localStorage[LAST_ACCESSED_DATES] = JSON.stringify(lastAccessedDates);
    },

    async saveZip(id, zip) {
        if (await this.keyExists(id)) {
            return;
        }

        this.updateLastAccessed(id);

        return new Promise((resolve, reject) => {
            var tx = this.db.transaction('zips', "readwrite");
            var store = tx.objectStore('zips');
            var item = {
                id: id,
                zip: zip
            };
            
            var request = store.add(item);
            
            request.onsuccess = () => {
                resolve();
            }

            request.onerror = (e) => {
                console.error(e);
                reject();
            }
        });
    },

    async deleteZip(id) {
        if (await this.keyExists(id)) {
            return;
        }

        this.updateLastAccessed(id);

        return new Promise((resolve, reject) => {
            var tx = this.db.transaction('zips', "readwrite");
            var store = tx.objectStore('zips');
            
            var request = store.delete(id);
            
            request.onsuccess = () => {
                resolve();
            }

            request.onerror = (e) => {
                console.error(e);
                reject();
            }
        });
    }
};