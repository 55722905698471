const DEFAULT_SCREEN_SIZE = {
    width: 375,
    height: 812
}

const SCREEN_BORDER_RATIO = 0.02;
const SCREEN_BORDER_RADIUS_RATIO = 0.135;

export default {

    SCREEN_BORDER_RADIUS_RATIO: SCREEN_BORDER_RADIUS_RATIO,
    SCREEN_BORDER_RATIO: SCREEN_BORDER_RATIO,

    getScreenSize(version, scale) {
        var fullScreenSize = DEFAULT_SCREEN_SIZE;

        if (version.definition && version.definition.deviceSize) {
            fullScreenSize = version.definition.deviceSize;
        }

        return {
            width: fullScreenSize.width * scale,
            height: fullScreenSize.height * scale
        }
    }

}