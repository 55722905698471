import React from "react";

const Logo = props => (
  <svg width="1em" height="1em" viewBox="0 0 206 200" className={props.className} style={props.style} onClick={props.onClick}>
    <g fill="none" fillRule="evenodd">
      <path
        strokeWidth={props.theme.mode === "dark" ? 10 : 10}
        stroke={props.theme.mode === "dark" ? props.theme.colors.black : props.theme.colors.black}
        fill="transparent"
        d="M17 17h168v168H17z"
      />
      <rect fill={props.theme.colors.logoCircle} width={40} height={40} rx={20} />
      <rect fill={props.theme.colors.logoCircle} x={166} width={40} height={40} rx={20} />
      <rect fill={props.theme.colors.logoCircle} x={166} y={160} width={40} height={40} rx={20} />
      <rect fill={props.theme.colors.logoCircle} y={160} width={40} height={40} rx={20} />
      <path
        className={props.animated ? "animated-path" : null}
        stroke={props.theme.colors.black}
        strokeWidth={8}
        d="M44 110.517L59.25 92l15.25 18.517L89.75 92 105 110.517 120.25 92l15.25 18.517L150.75 92 166 110.517"
      />
    </g>
  </svg>
);

export default Logo;