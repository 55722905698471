import JSZip from "jszip";
import BrowserStorageServices from "./BrowserStorageServices";
import Constants from "../utils/constants";
import axios from "axios";

const MISSING_IMAGE_TRANSPARENT_PNG = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

export default {

    db: null,
    cachedFiles: {},
    font: null,

    async loadRemoteFile(url, onDownloadProgress) {
        return axios
            .get(url, {
                responseType: "blob",
                onDownloadProgress: onDownloadProgress
            })
            .then(function(response) {
                if (response.status === 200 || response.status === 0) {
                    let blob = new Blob([response.data]);
                    return Promise.resolve(blob);
                } else {
                    return Promise.reject(new Error(response.statusText));
                }
            })
            .then(JSZip.loadAsync)                          
            .then(function (zip) {
                return zip;
            });
    },

    async extractImageToCache(mode, zip, id, subdirectory) {

        var filename = `preview_${id}.png`;

        if (mode === Constants.THEME_DARK) {
            filename = `dark_${filename}`;
        }

        filename = `${subdirectory}/${filename}`;

        await this.cacheImageFile(zip, id, filename, mode);
    },

    getLetterFromFilename(filename) {
        var letter = filename.substr(filename.lastIndexOf("_") + 1);
        letter = letter.substr(0, letter.indexOf(".png"))
        return letter;
    },

    async extractFontFiles(fontId, zip, mode) {
        if (!fontId) {
            return null;
        }

        var fontFiles = [];

        zip.folder(`font/${fontId}`).forEach((zipFile) => {
            fontFiles.push(zipFile);
        });

        // TODO Cache Font
        return await this.getFontFile(zip, fontId, `font/${fontId}/font.ttf`);
    },

    async getFontFile(zip, id, filename) {
        return await zip.file(filename).async("base64");
    },

    async loadRemoteFileAndCache(roughupVersion, url, onDownloadProgress) {
        const zipFile = await this.loadRemoteFile(url, onDownloadProgress);
        
        try {
            const zipBlob = await zipFile.generateAsync({type:"blob"});
            await BrowserStorageServices.saveZip(roughupVersion.id, zipBlob);
        } catch (e) {
            // Couldn't cache
            console.log("Couldn't cache", e);
        }
        
        return zipFile;
    },

    async loadRemoteFileOrCache(roughupVersion, url, onDownloadProgress) {
        // First try and load the Zip file from browser cache
        try {
            await BrowserStorageServices.connect();
            const isCached = await BrowserStorageServices.keyExists(roughupVersion.id);

            if (isCached) {
                onDownloadProgress({unknown: true});
                const blobZip = BrowserStorageServices.getZip(roughupVersion.id);
                return await JSZip.loadAsync(blobZip, {blob: true});
            } else {
                return await this.loadRemoteFileAndCache(roughupVersion, url, onDownloadProgress);
            }
        } catch (e) {
            return await this.loadRemoteFileAndCache(roughupVersion, url, onDownloadProgress);
        }
    },

    async extractRemoteScreen(screenDefinition, zip, screenId, mode, subfolder) {
        await this.extractImageToCache(mode, zip, screenId, subfolder);

        // Check for any toggle images
        if (screenDefinition.hotspots) {
            for (const hotspotId of Object.keys(screenDefinition.hotspots)) {
                const hotspot = screenDefinition.hotspots[hotspotId];

                if ((hotspot.fieldType && hotspot.fieldType === Constants.HOTSPOT_FIELD_TYPE_TOGGLE) || (hotspot.componentType && hotspot.componentType === Constants.COMPONENT_TYPE_SIMPLE_ANIMATE)) {
                    await this.extractImageToCache(mode, zip, hotspotId, "toggle");
                }
            }
        }
    },

    async extractRemoteFile(roughupVersion, url, mode, onDownloadProgress) {
        const zip = await this.loadRemoteFileOrCache(roughupVersion, url, onDownloadProgress);

        // Loop through screens
        for (const screenId of Object.keys(roughupVersion.definition.screens)) {
            await this.extractRemoteScreen(roughupVersion.definition.screens[screenId], zip, screenId, mode, "screens");
        }

        // Loop through components
        if (roughupVersion.definition.components) {
            for (const componentId of Object.keys(roughupVersion.definition.components)) {
                // Loop through all screens in this component
                const component = roughupVersion.definition.components[componentId];
                
                for (const screenId of Object.keys(component.screens)) {
                    await this.extractRemoteScreen(component.screens[screenId], zip, screenId, mode, `components/${componentId}`);
                }
            }
        }

        // Now load any fonts
        const fontData = await this.extractFontFiles(roughupVersion.definition.font, zip, mode); 
        this.font = fontData;

        return zip;
    },

    async cacheImageFile(zip, id, filename, mode) {
        try {
            this.cachedFiles[id] = await zip.file(filename).async("base64");
        } catch (e) {
            // Do nothing
            // File is missing - could be because screen is blank
        }
    },

    getCachedImage(id, mode) {
        if (!this.cachedFiles[id]) {
            return MISSING_IMAGE_TRANSPARENT_PNG;
        }
        
        return "data:image/png;base64," + this.cachedFiles[id];
    },

    async saveImageFile(zip, id) {
        await zip.file(`versions-${id}.png`).async("base64");
        //await BrowserStorageServices.saveImage(id, imgStr);
    }


};