import React from "react";
import Logo from "../components/Logo";

export default({theme}) => {
    const localStyles = {
        container: {
            flex: 1,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        logo: {
            width: 60,
            height: 60
        }
    };

    return (
        <div style={localStyles.container}>
            <Logo
                theme={theme}
                style={localStyles.logo}
            />
        </div>
    );
};